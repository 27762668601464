import React, { useContext,useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext'; // Adjust the path
import { Trans, useTranslation } from 'react-i18next'; // Text


import { CssVarsProvider } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Drawer from '@mui/joy/Drawer';
import FormControl from '@mui/joy/FormControl';
import FormLabel, { formLabelClasses } from '@mui/joy/FormLabel';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';
import Stack from '@mui/joy/Stack';
import Sheet from '@mui/joy/Sheet';
import { useTheme } from '@mui/joy/styles';
import LinearProgress from '@mui/joy/LinearProgress';
import { useColorScheme as useMaterialColorScheme } from '@mui/material/styles';


export default function SigninPage({baseUrl}) {
  const {isAuthenticated, isLoading, setIsAuthenticated, userBusinessId, setUserEmail, userRole,setUserRole, setUserBusinessId, setUserBusinessName } = useContext(AuthContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation(); 
  const { mode} = useMaterialColorScheme();

  const  [isDrawerOpen, setIsDrawerOpen]= useState(false);

  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email');
    const password = formData.get('password');

    try {
      // console.log(`Trying login ${email} 
      // \n ${password}`)
      const user = await axios.post(`${baseUrl}/api/v1/auth/login`, {
        email,
        password,
      },{ withCredentials: true } );
       // Store the received token or user data
      localStorage.setItem('authToken', user.data.token);
      // Update the authentication context
      setIsAuthenticated(true);
      setUserEmail(user.data.email)
      setUserRole(user.data.role)
      setUserBusinessId(user.data.businessId)
      setUserBusinessName(user.data.businessName)
      // setLoginSuccess(true);
      navigate('/admin/events/');
    } catch (error) {
      console.error('Login failed:', error.response?.data || error);
      setErrorMessage(t('loginError'))
    }
  };

  const handleGoogleSignIn = () => {
          // Redirect to Google OAuth route
      window.location.href = `${baseUrl}/api/v1/auth/google`;
  };

  useEffect(() => {
    // Optionally call checkAuthStatus if you need to explicitly check auth status on component mount
    // checkAuthStatus();

  if (!isLoading && isAuthenticated) {    
    if (userBusinessId == null && userRole !== 'super-admin')
      {
        navigate('/admin/onboarding/');
      }
      else {
        navigate('/admin/events/');
      }
    }
  }, [isLoading, isAuthenticated, navigate,userBusinessId, userRole]);

  if (isLoading) {
    return  <LinearProgress color="neutral" variant="outlined" />;
  }

  const toggleDrawer = (open) => (event) => {
    console.log('Clicked adminlogin')
    setIsDrawerOpen(open);
  };



  return (
    <CssVarsProvider defaultMode="light" disableTransitionOnChange>
      <GlobalStyles
        styles={{
          ':root': {
            '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
            '--Cover-width': '50vw', // must be `vw` only
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={{
          width:
            'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(12px)',
          backgroundColor: 'rgba(255 255 255 / 0.2)',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width:
              'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
            maxWidth: '100%',
            px: 2,
          }}>
          <Box
            component="header"
            sx={{
              py: 3,
              display: 'flex',
              alignItems: 'left',
              justifyContent: 'space-between',
            }}>
          </Box>
          <Box
            component="main"
            sx={{
              my: 'auto',
              py: 2,
              pb: 5,
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
              width: 400,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: 'hidden',
              },
            }}
          >            
            <Stack gap={2}>            
            <img src={`/Logotype_${mode}.svg`} alt="Orbiner Logo" style={{ height: '32px', width: 'auto', alignSelf: 'start' }} />
            <Typography level="h1" sx={{ textAlign: 'left' }}>
            <Trans
              i18nKey="signUpOrLogin"
              components={[
                <span style={{ color: theme.vars.palette.primary.accentColor }}/>
              ]}
            />
            </Typography>

            <Button
                variant="outlined"
                color="neutral"
                onClick={handleGoogleSignIn} 
                startDecorator={<img src="/google_icon.svg" alt="Google Logo" style={{ height: '24px', width: 'auto' }}/>
              }>{t('googleAuth')}
            </Button>

            <Box sx={{ display:"flex", direction:"row", alignItems:"center"}}>
              <span className="material-symbols-outlined" style={{fontSize:'14px'}}>arrow_back</span> 
            <Typography level="body-xs">{t('goBacktoSite')} </Typography>
            <Typography component="a" href="https://orbiner.com" level="body-xs">{t('orbinerSite')}</Typography>
            </Box>
          </Stack>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
             © Orbiner {new Date().getFullYear()}
             <span
              onClick={(event) => toggleDrawer(true)(event)}
              style={{ cursor: 'pointer'}} 
              >
               - adminlogin
              </span>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          m:5,
          height: '50vh', 
          position: 'fixed',
          right: 0,
          top:'50%',
          transform: 'translateY(-50%)', 
          bottom: 0,
          left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: 'url(/signin_illustration.png)',
         }}
      />
      <Drawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={toggleDrawer(false)}
        variant='outlined'
        color='primary'
        borderRadius='sm'>        
         <Box
            sx={{
              display: 'flex',
              flexDirection: 'column', 
              alignItems:'center',
              justifyContent: 'space-between', 
              height: '100%', 
              p: 2,
            }}
          >
          {/* Drawer Content */}
            <Stack 
              alignItems="left" 
              justifyContent="space-between" 
              spacing={2}
              sx={{maxWidth:'640px'}}>
              <form onSubmit={handleLogin}>
                <FormControl required>
                  <FormLabel>Email</FormLabel>
                  <Input type="email" name="email" />
                </FormControl>
                <FormControl required>
                  <FormLabel>{t('password')}</FormLabel>
                  <Input type="password" name="password" />
                </FormControl>
                <Stack direction="row" spacing={1}>                
                  <Button variant="plain" color="neutral" onClick={toggleDrawer(false)}>{t('cancel')}</Button>
                  <Button type="submit" fullWidth>
                    {t('signin')}
                  </Button>
                </Stack>
              </form>
            </Stack>
            {errorMessage && (
              <Sheet color="danger" variant="soft" sx={{ px:2, py:1, borderRadius:'sm'}}>
                {errorMessage}
              </Sheet>)}
        </Box>
     </Drawer>
    </CssVarsProvider>
  );
}
