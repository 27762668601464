// src/useNotification.js
import { useState } from 'react';
import {
  requestNotificationPermission,
  scheduleNotification,
} from './utils/NotificationModel';

const useNotification = () => {
  const [isNotificationGranted, setIsNotificationGranted] = useState(false);

  const requestPermission = async () => {
    const granted = await requestNotificationPermission();
    setIsNotificationGranted(granted);
    console.log(`permission ${granted}`);
  };

  const notify = (title, options, delay) => {
    if (isNotificationGranted) {
      scheduleNotification(title, options, delay);
    }
  };

  return { isNotificationGranted, requestPermission, notify };
};

export default useNotification;
