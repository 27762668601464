import React, {useRef, useState } from 'react';
import QRCode from 'react-qr-code';
import { Box, Typography } from '@mui/joy';
import { useTheme } from '@mui/joy/styles';

import { useTranslation } from 'react-i18next';


const QRCodeComponent = ({ link, imageName, size }) => {
  const theme = useTheme();
  const { t } = useTranslation(); 
  const [isHovered, setIsHovered] = useState(false);
  const qrRef = useRef();

  const handleDownload = () => {
    const svgElement = qrRef.current.querySelector('svg');
    const svgData = new XMLSerializer().serializeToString(svgElement);
    const canvas = document.createElement('canvas');
    canvas.width = svgElement.clientWidth;
    canvas.height = svgElement.clientHeight;
    const ctx = canvas.getContext('2d');
    const img = new Image();
    const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
    const url = URL.createObjectURL(svgBlob);

    img.onload = () => {
      ctx.drawImage(img, 0, 0);
      URL.revokeObjectURL(url);
      const pngUrl = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = `${imageName}.png`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };
    img.src = url;
  };

  return (
    <Box
      sx={{
        transition: 'all 0.3s', 
        cursor: 'pointer', // Change cursor to hand on hover
        position: 'relative',
        '&:hover': {
          '& .qrCode': {
            filter: 'blur(10px)',
          },
          '& .hoverText': {
            display: 'flex',
          },
        },
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleDownload} 
      ref={qrRef}
    >
      <div id="qrCode" className="qrCode">
        <QRCode value={link} size={size}  />
        </div>
      {isHovered && (
        <Typography
          className="hoverText"
          component="div"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
            display: 'none',
            alignItems: 'center',
            justifyContent: 'center',
            color: theme.palette.text.primary,
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            borderRadius: '8px',
          }}
        >
          {t('download')}
        </Typography>
      )}
    </Box>
  );
};
export default QRCodeComponent;