// src/utils/NotificationModel.js
const requestNotificationPermission = async () => {
    if ('Notification' in window && 'serviceWorker' in navigator) {
      const permission = await Notification.requestPermission();
      return permission === 'granted';
    }
    return false;
  };
  
  const scheduleNotification = (title, options, delay) => {
    if ('serviceWorker' in navigator && 'Notification' in window) {
        if (Notification.permission === 'granted') {
            setTimeout(() => {
                navigator.serviceWorker.ready.then(registration => {
                registration.showNotification(title, options);
                }).catch(err => {
                console.error('Service Worker not ready:', err);
                });
            }, delay);
        }
    }
  };

  export {requestNotificationPermission, scheduleNotification}