// components/WelcomeModalComponent.js
import React, {useState,useEffect}from 'react';
import { Modal,  ModalDialog, DialogContent, Button, Stack,DialogTitle, IconButton, Box, Divider,Snackbar } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import { useTheme } from '@mui/joy/styles';
import { useTranslation } from 'react-i18next';

import {formatDate} from '../../utils/dateFormat'
import QRCodeComponent from '../../components/QRCodeComponent';
import axios from 'axios';
import { useColorScheme as useMaterialColorScheme } from '@mui/material/styles';

const ShareModalComponent = ({baseUrl, open,handleClose,eventData, userBusinessId}) => {
  const frontendUrl = process.env.REACT_APP_FRONTEND_URL 
    const theme = useTheme();
    const { t } = useTranslation(); 
    const { mode} = useMaterialColorScheme();

    const [successSnackBarOpen, setSuccessSnackBarOpen] = useState(false);
    const [businessLogo, setBusinessLogo]= useState(null);
    // Function to handle file selection
    const handleCopyText = (type) => {
      navigator.clipboard.writeText(`${frontendUrl}/events/${type}`)
      setSuccessSnackBarOpen(true);
    };

    // Get Settings
    useEffect(() => {
      const fetchBusinessInfo = async () => {
          try {
              const businessResponse = await axios.get(`${baseUrl}/api/v1/businesses/admin/${userBusinessId}`, { withCredentials: true });
              if (businessResponse.data.logoPath){
                  // if logo exist
                  setBusinessLogo(businessResponse.data.logoPath);
              }
          } catch (error) {
              console.error('Failed to fetch business info:', error);
          }
      };
      fetchBusinessInfo();
    }, [baseUrl, userBusinessId]);


  return (
    <Modal 
        open={open} 
        onClose={() => {}} 
        aria-labelledby="share-modal-title"
        sx={{ backdropFilter: 'blur(10px)' }}    
    >
      <ModalDialog variant="outlined" color="primary" 
      sx={{ maxWidth: '450px'}}
        > 
        <DialogTitle>
          {t('shareEventLongTitle',{location:eventData.location, date:formatDate(eventData.scheduledTime)})}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', gap:2}}>
            {/* Lien Public */}
              <Typography level="h5">{t('publicLinkTitle')}</Typography>
            <Box 
            sx={{ display: 'inline-flex',
              flexDirection:'column', 
              alignItems: 'center',
              mx: 'auto', px:2,py:1, 
              border: '1px solid',
              borderColor: 'divider', 
              width:'fit-content',
              borderRadius: '8px'}}>
              <Typography>{`${eventData.location} - ${formatDate(eventData.scheduledTime)}`}</Typography>
              <QRCodeComponent 
                link={`${frontendUrl}/events/${eventData.publicLink}`} 
                imageName={`${eventData.publicLink}`}
                size={128} />
                <Box sx={{display:'flex', flexGrow: 1, justifyContent: 'space-between', width:'100%'}}>
                  <img src={`/Logotype_${mode}.svg`} alt="Orbiner Logo" style={{ height: '16px' }} />
                  {businessLogo && (<img src={businessLogo} alt="Business Logo" style={{ height: '16px' }} />)}
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap:1}}>  
              <Box sx={{
                display: 'flex', 
                alignItems: 'center', 
                bgcolor: mode ==='dark' ? theme.palette.primary[600] : theme.palette.primary[50], 
                borderRadius: 'md', 
                border: '1px solid',
                borderColor: 'divider', 
                width: 'fit-content'
              }}>
                <IconButton aria-label="copy" onClick={() => handleCopyText(eventData.publicLink)} sx={{ width: 12, height: 12 }}>
                  <span className="material-symbols-outlined" style={{ fontSize: '14px' }}>content_copy</span>
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <Typography sx={{ mx: 1, textAlign: 'center', fontSize: '14px' }}>
                  {`${frontendUrl}/events/${eventData.publicLink}`}
                </Typography>
              </Box>
              <IconButton 
                  onClick={() => window.open(`${frontendUrl}/events/${eventData.publicLink}`, '_blank')} 
                  variant='outlined' className="action-icons">
                  <span className="material-symbols-outlined">open_in_new</span>
              </IconButton>
            </Box>

            {/* Lien Pyro */}
            <Typography level="h5">{t('pyroLinkTitle')}</Typography>
            <Box 
            sx={{ display: 'inline-flex',
              flexDirection:'column', 
              alignItems: 'center',
              mx: 'auto', px:2,py:1, 
              border: '1px solid',
              borderColor: 'divider', 
              width:'fit-content',
              borderRadius: '8px'}}>
              <Typography>{`${eventData.location} - ${formatDate(eventData.scheduledTime)}`}</Typography>
              <QRCodeComponent 
                link={`${frontendUrl}/events/pyro/${eventData.id}`} 
                imageName={`Pyro_${eventData.publicLink}`}
                size={128} />
                <Box sx={{display:'flex', flexGrow: 1, justifyContent: 'space-between', width:'100%'}}>
                  <img src={`/Logotype_${mode}.svg`} alt="Orbiner Logo" style={{ height: '16px' }} />
                  {businessLogo && (<img src={businessLogo} alt="Business Logo" style={{ height: '16px' }} />)}
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap:1}}>  
              <Box sx={{
                display: 'flex', 
                alignItems: 'center', 
                bgcolor: mode ==='dark' ? theme.palette.primary[600] : theme.palette.primary[50],  
                borderRadius: 'md', 
                border: '1px solid',
                borderColor: 'divider', 
                width: 'fit-content'
              }}>
                <IconButton aria-label="copy" onClick={() => handleCopyText(eventData.id)} sx={{ width: 12, height: 12 }}>
                  <span className="material-symbols-outlined" style={{ fontSize: '14px' }}>content_copy</span>
                </IconButton>
                <Divider orientation="vertical" flexItem />
                <Typography sx={{ mx: 1, textAlign: 'center', fontSize: '14px' }}>
                  {`${frontendUrl}/events/pyro/${eventData.id}`}
                </Typography>
              </Box>
              <IconButton 
                  onClick={() => window.open(`${frontendUrl}/events/pyro/${eventData.id}`, '_blank')} 
                  variant='outlined' className="action-icons">
                  <span className="material-symbols-outlined">open_in_new</span>
              </IconButton>
            </Box>

            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button variant="plain" color="neutral" onClick={handleClose}>
                {t('close')}
              </Button>
            </Stack>          
          </Box>
          <Snackbar
            autoHideDuration={800}
            open={successSnackBarOpen}
            variant='outlined'
            color='primary'
            size="sm"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            onClose={() => setSuccessSnackBarOpen(false)} 
          >
            {t('linkCopiedSnackbar')}
          </Snackbar>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default ShareModalComponent;