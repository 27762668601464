import { useState, useEffect } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import { t } from 'i18next';

export default function useEventInfo(baseUrl, apiName) {
  const [eventInfo, setEventInfo] = useState(null);
  const [scheduledTime, setScheduledTime] = useState(null);
  const [testTime, setTestTime]= useState(null);
  const [error, setError] = useState(null);
  const [isEventPassed, setEventIsPassed] = useState(false);

  useEffect(() => {
    const fetchEventInformation = async () => {
      try {
        // apiName = /public/publicLink
        // apiName= /pyro/eventId
        // console.log(`${baseUrl}/api/v1/events/${apiName}`)
        const response = await axios.get(`${baseUrl}/api/v1/events/${apiName}`); 
        let eventData = response.data; 
        console.log(eventData)
        // console.log(eventData.isPassed)
        if (!eventData.isPassed) {
          setEventInfo(eventData);
          setScheduledTime(new Date(eventData.scheduledTime)); 
          setTestTime(new Date(eventData.testTime) )
        } else {
          const fallbackEventData = {
            id: eventData.id,
            imgPath: eventData.imgPath, 
            name: eventData.name,
            description:t('eventFinishedDescription'),
            isPassed: eventData.isPassed
          };        
          setEventInfo(fallbackEventData);   
          setEventIsPassed(true)
        }
      } catch (error) {
        console.error('Failed to fetch event information:', error);
        setError(error);
        const fallbackEventData = {
          imgPath: '/fireworks_404_events.svg', 
          name: "Oh Oh...",
          description: t('eventNotFoundDescription'),
        };        
        setEventInfo(fallbackEventData);   
      }
    };

    if (baseUrl && apiName) {
      fetchEventInformation();
    }

     // Establish WebSocket connection
    // Determine the WebSocket URL based on the environment
    const wsBaseUrl = baseUrl.replace(/^http/,'ws'); 
    const socket = io(wsBaseUrl, { path: '/socket.io' });

    // Listen for scheduled or test time updates
    socket.on('event-updated', (updatedEvent) => {
      console.log(updatedEvent)
      if (updatedEvent.id === eventInfo?.id) {
        // Check if scheduledTime was part of the update
        if (updatedEvent.scheduledTime) {
          console.log(`WebSocket updated: ${updatedEvent.scheduledTime}`);
          setScheduledTime(new Date(updatedEvent.scheduledTime));
        }
        // Check if testTime was part of the update
        if (updatedEvent.testTime) {
          console.log(`WebSocket updated: ${updatedEvent.testTime}`);
          setTestTime(new Date(updatedEvent.testTime));
        }
      } else {
        console.log(`Received update for a different event: ${updatedEvent.id}`);
      }
    });
    // Cleanup function to run when the component unmounts
    return () => {
      if (socket) {
          socket.disconnect();
      }
    };
  }, [eventInfo?.id, baseUrl,apiName]);

  return { eventInfo, scheduledTime,testTime, error, isEventPassed };
}