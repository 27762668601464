import { useRef, useState } from 'react';

const useWakeLock = () => {
  const wakeLockRef = useRef(null);
  const [wakeLockActive, setWakeLockActive] = useState(false);

  const enableWakeLock = async () => {
    try {
      wakeLockRef.current = await navigator.wakeLock.request('screen');
      setWakeLockActive(true);
    } catch (err) {
      console.error(`Wake Lock failed: ${err.name}, ${err.message}`);
      setWakeLockActive(false);
    }
  };

  const disableWakeLock = async () => {
    if (wakeLockRef.current !== null) {
      await wakeLockRef.current.release();
      wakeLockRef.current = null;
      setWakeLockActive(false);
    }
  };

  return { wakeLockActive, enableWakeLock, disableWakeLock };
};

export default useWakeLock;