import React, { useState, useEffect } from 'react';
// import { useParams } from 'react-router-dom'; // To get dynamic Url from router dom
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Text


import BusinessesTable from "./BusinessesTable.js";
import SideBarComponent from "../../components/SideBarComponent.js"

import { CssBaseline, Box} from '@mui/joy';


const AdminBusinessesPage = ({baseUrl}) => {
  const { t } = useTranslation(); 

  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
      const fetchAllBusinesses = async () => {
        try {
          // get all Businesses
          const businessResponse = await axios.get(`${baseUrl}/api/v1/businesses/`,{ withCredentials: true });      
          // console.log(businessResponse.data.data)      
          setBusinesses(businessResponse.data);          
        } catch (error) {
          console.error('Failed to fetch all businesses:', error);
          setError(error);
        } finally {
          setLoading(false);
        }
      };
      fetchAllBusinesses();
  }, [baseUrl]);


  const handleDeleteBusiness = async (selectedBusinessId) => {
    await axios.delete(`${baseUrl}/api/v1/businesses/${selectedBusinessId}`,{ withCredentials: true });
    setBusinesses(businesses.filter(business => business.id !== selectedBusinessId));
  
  };

          
  return (
    <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <SideBarComponent baseUrl={baseUrl}/> 
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        padding: '1em'
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '1em' }}>
        <h2>{t('businesses')}</h2>
      </Box>
      <Box className="admin-table">
      {businesses.length > 0 ? (
        <BusinessesTable 
          businesses={businesses} 
          onDeleteBusiness={handleDeleteBusiness} 
        />) : (
          <div>{t('noBusinesses')}</div>
        )}
      </Box>
    </Box>
  </Box>
  );
};

export default AdminBusinessesPage;