import React, { useContext,useState, useEffect } from 'react';

import axios from 'axios';
import { AuthContext } from '../contexts/AuthContext'; // Adjust the path accordingly
import { useNavigate, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';


import GlobalStyles from '@mui/joy/GlobalStyles';
// import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Divider from '@mui/joy/Divider';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';

import { useColorScheme as useMaterialColorScheme } from '@mui/material/styles';

import { closeSidebar } from '../utils';

export default function Sidebar({baseUrl, settings}) {
  
  const { setIsAuthenticated, userBusinessName, setUserEmail, userRole, setUserRole, setUserBusinessId,setUserBusinessName } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation(); // Get current location

  const { t } = useTranslation(); 

  // Define paths for comparison
  const eventsPath = '/admin/events/';
  const usersPath = '/admin/users/';
  const businessesPath = '/admin/businesses/';
  const settingsPath = '/admin/settings/';
  const adminSettingsPath = '/settings/'

  // Check if current path matches the events or users path
  const isEventsPage = location.pathname === eventsPath;
  const isUsersPage = location.pathname === usersPath;
  const isBusinessesPage = location.pathname === businessesPath;
  const isSettingsPage = location.pathname === settingsPath;

  const { mode} = useMaterialColorScheme();


  // For testing Onboarding page
  // const onboardingPath = '/admin/onboarding/';
  // const isOnBoardingPage = location.pathname === onboardingPath;

  const handleLogout = async () => {
    try {
     await axios.post(`${baseUrl}/api/v1/auth/logout`, {}, { withCredentials: true });
      // Clear any client-side stored authentication data
      localStorage.removeItem('authToken');
      setIsAuthenticated(false);
      setUserEmail(null);
      setUserRole(null);
      setUserBusinessId(null);
      setUserBusinessName(null);
      navigate('/signin');
    } catch (error) {
      console.error('Logout failed:', error);
      // TODO Handle error (e.g., show error notification)
    }
  };


 return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 21,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        borderRight: '1px solid',
        borderColor: 'divider',
      }}>
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}/>
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 20,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}/>
      <Box sx={{ gap: 2, display: 'flex', flexDirection:'column', alignItems: 'center' }}>
        <img src={`/Logotype_${mode}.svg`} alt="Orbiner Logo" style={{ height: '16px', width: 'auto' }} />
      </Box>
      <Divider />
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },}}>

        {/* Core sidebar list */}
        {!settings && (
        <List
          size="sm"
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}>
          {/* <ListItem >            
            <ListItemButton 
              onClick={() => navigate(onboardingPath)}
              selected={isOnBoardingPage}
              >
                  <span className="material-symbols-outlined">apartment</span>
              <ListItemContent>
                <Typography level="title-sm">OnBoarding</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem> */}
          <ListItem >            
            <ListItemButton 
              onClick={() => navigate(eventsPath)}
              selected={isEventsPage}>
                  <span className="material-symbols-outlined">event</span>
              <ListItemContent>
                <Typography level="title-sm">{t('events')}</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
          <>
            {userRole === 'super-admin' && (
              <>
              <ListItem>
                <ListItemButton
                  onClick={() => navigate(usersPath)}
                  selected={isUsersPage}
                >
                  <span className="material-symbols-outlined">person</span>
                  <ListItemContent>
                    <Typography level="title-sm">{t('users')}</Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  onClick={() => navigate(businessesPath)}
                  selected={isBusinessesPage}
                >
                  <span className="material-symbols-outlined">domain</span>
                  <ListItemContent>
                    <Typography level="title-sm">{t('businesses')}</Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton
                  onClick={() => navigate(settingsPath)}
                  selected={isSettingsPage}
                >
                  <span className="material-symbols-outlined">settings</span>
                  <ListItemContent>
                    <Typography level="title-sm">{t('settings')}</Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
              </>
            )}
          </>
        </List>)}

        {/* Settings sidebar */}
        {settings && (
        <List
          size="sm"
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}>
          <ListItem >            
            <ListItemButton onClick={() => navigate(eventsPath)}>
                  <span className="material-symbols-outlined">arrow_back</span>
              <ListItemContent>
                <Typography level="title-xs">{t('settingsBack')}</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>)}


     </Box>
 
     {!settings && (
      <>
      <Divider/>
      <Dropdown>
        <MenuButton 
          endDecorator={<span className="material-symbols-outlined">expand_less</span>}>
          <Typography level="title-md">{userBusinessName}</Typography>
        </MenuButton>
        <Menu sx={{zIndex: 30}}>
          <MenuItem onClick={() => navigate(adminSettingsPath)}>          
            <span className="material-symbols-outlined">settings</span>
            {t('settings')}
          </MenuItem>
          <MenuItem onClick={handleLogout}>          
            <span className="material-symbols-outlined">logout</span>
            {t('logout')}
          </MenuItem>
        </Menu>
      </Dropdown>
      </>
    )}
     
    </Sheet>
  );
}