import { useState, useRef, useEffect } from 'react';
import io from 'socket.io-client';
const timesync = require('timesync');

// import axios from 'axios';

const useNTPTime = (baseUrl, audioContext, audioContextState, scheduledTime) => {
    const [ntpOffset, setntpOffset] = useState(null);
    const [playbackDelta, setPlaybackDelta] = useState(null);
    const [deltaScheduledWServer,setDeltaScheduledWServer]= useState(null);
    const [isSynchronized,setIsSynchronized]=useState(false);

    const [syncStatus, setSyncStatus] = useState(null);
    const [syncAttempts, setSyncAttempts] = useState(0);


    const socketRef = useRef(null);
    const tsRef = useRef(null);
    const syncIntervalRef = useRef(null);

    // The page is now visible
    // Trigger a sync
    useEffect(() => {
        const handleVisibilityChange = () => {
            // console.log('UseNTP : Page is active!')
          if (!document.hidden) {
            tsRef.current.sync();
          }
        };
      
        document.addEventListener('visibilitychange', handleVisibilityChange);
      
        return () => {
          document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
      }, []);

    // Setup WebSocket and TimeSync
    useEffect(() => {
        console.log('Setting up websocket & Timesync')
        const wsBaseUrl = baseUrl.replace(/^http/, 'ws'); 
        const socket = io(wsBaseUrl, { path: '/socket.io' });
        socketRef.current = socket;

        const ts = timesync.create({
            server: socket,
            interval: null, // Disable automatic synchronization
        });
        tsRef.current = ts;

        ts.on('sync', function (state) {
            console.log('sync ' + state + '');
            setSyncStatus(state)
        });

        ts.on('change', function (newntpOffset) {
            // console.log(`ntpOffset changed : ${newntpOffset} ms`);
            setntpOffset(newntpOffset);
        });

        ts.send = function (socket, data, timeout) {
            console.log('send', data);
            return new Promise(function (resolve, reject) {
              var timeoutFn = setTimeout(reject, timeout);      
              socket.emit('timesync', data, function () {
                clearTimeout(timeoutFn);
                resolve();
              });
            });
          };

        socket.on('timesync', function (data) {
            ts.receive(null, data);
        });
        

    return () => {
        ts.destroy();
        socket.disconnect();
        setSyncStatus(null); // Reset sync status

    };
    }, [baseUrl]);


    useEffect(() => {
        // Immediate sync
        tsRef.current.sync();
    }, []);

     useEffect(() => {
        // Calculates Delta to launch music after syncing ends
        if (syncStatus === 'end' && scheduledTime && ntpOffset !== null) {
            setIsSynchronized(true)
            const adjustedServerTime = new Date(Date.now() + ntpOffset);
            const deltaScheduledWServer = (new Date(scheduledTime).getTime() - adjustedServerTime.getTime()) / 1000;
            setDeltaScheduledWServer(deltaScheduledWServer)
            // const deltaAudioContextRef = deltaScheduledWServer + 2 * audioContext.currentTime;
            let deltaAudioContextRef
            if (deltaScheduledWServer > 0) { // Starting music relative to the audioContext time 
                deltaAudioContextRef = deltaScheduledWServer + audioContext.currentTime;                
            } else { // Music is launched immediatly
                deltaAudioContextRef = deltaScheduledWServer;     
            }
            setPlaybackDelta(deltaAudioContextRef);

            // If event has passed, perform 1 syncs at 5 seconds intervals
            console.log(`Syncing 1 times : ${syncAttempts}`)
            if (syncAttempts < 1) {
                console.log('Syncing')
                syncIntervalRef.current = setInterval(() => {
                    if (syncAttempts < 1) {
                        tsRef.current.sync();
                        setSyncAttempts(attempts => attempts + 1);
                    } else {
                        clearInterval(syncIntervalRef.current);
                    }
                }, 5000);
            }         
            // console.log(` Calculating Playback delta 
            // \n UseNTP: (ntpOffset) : ${ntpOffset}
            // \n UseNTP: (AudioContextCurrent Time) : ${audioContext.currentTime}
            // \n UseNTP: (deltaScheduledWServer) : ${deltaScheduledWServer}
            // \n UseNTP: (Delta to AudioContextRef) : ${deltaAudioContextRef}
            // `);

            // Schedule Next sync
            let syncTimeout;
             if (deltaScheduledWServer > 5 * 60 + 10) {
                // If event hasn't started, sync again 5 minutes before scheduled time
                console.log(`Event is in more than 5 minutes : ${deltaScheduledWServer/60}`)
                syncTimeout = setTimeout(() => {
                    console.log('Setting timeout')
                    tsRef.current.sync();
                }, deltaScheduledWServer * 1000 - 5 * 60 * 1000); // 5 minutes before event    
            }
    
            // Clean up function
            return () => {
                if (syncIntervalRef.current) {
                    clearInterval(syncIntervalRef.current);
                }
                if (syncTimeout) {
                    clearTimeout(syncTimeout);
                }
            };
        }
    }, [scheduledTime, audioContextState, syncStatus]);

    // PlaybackDelta is the playback delta in seconds compared to the audioContext current time (that can be different than 0)
    // deltaScheduledWServer is the difference between the scheduled time (when the music needs to start) and the current time (synchronized w server)
    return {ntpOffset,isSynchronized,playbackDelta,deltaScheduledWServer};

};

export default useNTPTime;