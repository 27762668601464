function cleanFilename(file) {
    const cleanFileName = file.name.replace(/[^\w.]+/g, '_');
      const newFile = new File([file], cleanFileName, {
        type: file.type,
        lastModified: file.lastModified
      });
  
      console.log(`New filename : ${newFile.name}`)
    return newFile;
  }

  export {cleanFilename};