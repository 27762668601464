import React from 'react';
import Table from '@mui/joy/Table';

import IconButton from '@mui/joy/IconButton';
import { useTranslation } from 'react-i18next';


const UsersTable = ({ users, onEditUser, onDeleteUser}) => {
  const { t } = useTranslation(); 
  return (
      <Table aria-label="users table" variant="outlined" color="primary" hoverRow>
        <thead >
          <tr>
            <th align="left">Email</th>
            <th align="left">{t('business')}</th>
            <th align="left">{t('role')}</th>
            <th align="left">{t('verified')}</th>
            <th align="left">{t('action')}</th>
          </tr>
        </thead>
        <tbody  sx={{borderRadius: '2em' }} >
          {users.map((user) => (
            <tr
              key={user.id}
              sx={{
                '&:last-child td, &:last-child th': { },
                '&:hover': {
                  '.action-icons': { // Class for action icons
                    visibility: 'visible', // Only show icons when row is hovered
                  }
                },
                '.action-icons': {
                  visibility: 'hidden', // Initially hide icons
                }
              }}
            >
              <td align="left">{user.email}</td>
              <td align="left">{user.businessId}</td>
              <td align="left">{user.role}</td>
              <td align="left">{user.verified}</td>
              <td align="right">
                {/* <IconButton onClick={() => onEditUser(user.id)} className="action-icons">
                  <EditIcon />
                </IconButton> */}
                <IconButton onClick={() => onDeleteUser(user.id)} className="action-icons">
                <span className="material-symbols-outlined">delete</span>
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
  );
};

export default UsersTable;