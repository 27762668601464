import React, {useState} from 'react';
import Table from '@mui/joy/Table';
import Button from '@mui/joy/Button';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';


import { useTranslation } from 'react-i18next';
import {formatDateWHours} from '../../utils/dateFormat'

// import { Link } from 'react-router-dom';
import { useTheme } from '@mui/joy/styles';
import { Chip } from '@mui/joy/';
import { Typography } from '@mui/material';


const EventsTable = ({ events, onEditEvent, onDeleteEvent,onShareEvent, onPayEvent}) => {
 
  const theme = useTheme();
  const { t } = useTranslation(); 

  function getStatusProps(status) {
    switch (status) {
      case t('eventUnpaidStatus'):
        return { color: 'danger', variant: 'soft' };
      case 'paid':
        return { color: 'success', variant: 'soft' };
      case t('eventPassedStatus'):
        return { color: 'primary', variant: 'soft' };
      case 'Unpaid':
        return { color: 'danger', variant: 'soft' };
      default:
        return { color: 'primary', variant: 'outlined' }; 
    }
  }

  
  return (
      <Table aria-label="events table" variant="outlined" color="primary" hoverRow>
        <thead >
          <tr>
            <th align="left">{t('city')}</th>
            <th align="left">{t('name')}</th>
            {/* <th align="left">{t('description')}</th> */}
            <th align="left" style={{ width: '140px' }}>{t('date')}</th>
            <th align="left">{t('files')}</th>
            <th align="left" style={{ width: '80px' }}>{t('status')}</th>
            <th align="left" style={{ width:'180px'}}>{t('actions')}</th>
          </tr>
        </thead>
        <tbody  sx={{borderRadius: '2em' }} >
          {events.map((event) => (
            <tr
              key={event.id}
              style={{color: event.isPassed ? theme.palette.neutral[400] : 'inherit'}}
            >
              <td align="left"  >{event.location}</td>
              <td align="left">{event.name}</td>
              <td align="left" >{formatDateWHours(event.scheduledTime)}</td>
              <td align="left"> {`${event.originalEventImageName} \n ${event.originalMusicName}`}</td>
              <td align="left"  style={{ width: '80px' }}>{
              <Chip {...getStatusProps(event.status)}>{event.status}</Chip>
              }
              </td>
              <td align="left" style={{ textAlign:'center',width:'20px'}} >
                <Button variant="outlined" onClick={() => onShareEvent(event.id)} className="action-icons" sx={{px:1,py:0.5}}>
                    <span class="material-symbols-outlined" style={{fontSize: '1.2rem' }} >share</span>
                    <Typography 
                      level="body-xs" 
                      sx={{ fontSize: '1rem', }}
                    >{t('share')}</Typography> 
                </Button>
                <>
                <Dropdown>
                  <MenuButton variant="primary">
                    <span className="material-symbols-outlined">more_vert</span>
                  </MenuButton>
                  <Menu placement="top-end" sx={{zIndex: 30}}>
                    <MenuItem color="success" disabled onClick={() => onPayEvent(event.id)} className="action-icons" >          
                      <span className="material-symbols-outlined">payments</span> 
                      <Typography level="title-sm">{t('pay')}</Typography>
                    </MenuItem>
                    <MenuItem onClick={() => onEditEvent(event.id)} className="action-icons">          
                      <span className="material-symbols-outlined">edit</span>
                      <Typography level="title-sm">{t('edit')}</Typography>
                    </MenuItem>
                    <MenuItem color="danger" onClick={() => onDeleteEvent(event.id)} className="action-icons">          
                      <span className="material-symbols-outlined">delete</span>
                      <Typography level="title-sm">{t('delete')}</Typography>
                    </MenuItem>
                  </Menu>
                </Dropdown>
                </>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
  );
};

              // <td align="left">
              //   {/* TODO : replace by sharing button */}
              //   <Link to={`/events/${event.publicLink}`} target="_blank" rel="noopener noreferrer">
              //     Public Page
              //   </Link>
              // </td>
              // <td align="left">
              //   <Link to={`/events/pyro/${event.id}`} target="_blank" rel="noopener noreferrer">
              //     Pyro Page
              //   </Link>
              // </td>

export default EventsTable;