// AuthContext.js
import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children, baseUrl, isPublic }) => {
  
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem('authToken') !== null;
  });
  
  const [userEmail, setUserEmail] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userBusinessId, setUserBusinessId] = useState(null);
  const [userBusinessName, setUserBusinessName] = useState(null);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    // Function to check authentication status
    const checkAuthStatus = async () => {
      try {
        // console.log('CheckAuthStatus')
        const response = await axios.get(`${baseUrl}/api/v1/auth/me`, { withCredentials: true });
        // console.log(response)
        setIsAuthenticated(response.status === 200);
        setUserEmail(response.data.email); 
        setUserRole(response.data.role); 
        setUserBusinessId(response.data.businessId); 
        setUserBusinessName(response.data.businessName); 
        // console.log(`
        // ${response.data.email} 
        // \n ${response.data.role} 
        // \n ${response.data.businessId} 
        // `)
      } catch (error) {
        console.log(`Auth Context : ${error}`) 
        setIsAuthenticated(false);
        localStorage.removeItem('authToken'); 
        setUserEmail(null);
        setUserRole(null); 
        setUserBusinessId(null); 
        setUserBusinessName(null); 
      }  finally {
        setIsLoading(false); 
      }
    };
    if (!isPublic){
      checkAuthStatus();
    } else {
      setIsLoading(false); 
    }
  },);

  return (
    <AuthContext.Provider value={{ isLoading, isAuthenticated, setIsAuthenticated, userEmail, setUserEmail, userRole, setUserRole, userBusinessId, setUserBusinessId, userBusinessName, setUserBusinessName }}>
      {children}
    </AuthContext.Provider>
  );
};
