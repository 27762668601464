// pyropageJ'ad.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';

import {
  CssBaseline,
  Button,
  Box,
  Typography,
  Sheet,
  Tabs,
  TabList,
  Tab,
  Snackbar,
} from '@mui/joy';
import { useColorScheme as useJoyColorScheme } from '@mui/joy/styles';
import { useColorScheme as useMaterialColorScheme } from '@mui/material/styles';
import axios from 'axios';

// Custom Hooks
import useNTPTime from '../../useNTPTime';
import useEventInfo from '../../useEventInfo';
import useEventAudio from '../../useEventAudio.js';
import useWakeLock from '../../useWakeLock.js';
import useNotification from '../../useNotification.js';

// Custom Components
import MusicPlayerComponent from '../../components/MusicPlayerComponent';
import WelcomeModalComponent from '../../components/WelcomeModalComponent';
import QRCodeModalComponent from '../../components/QRCodeModalComponent';
import CountdownComponent from './CountDownComponent.js';
import BrandFooterComponent from '../../components/BrandFooterComponent.js';
import ChangeScheduledTimeDrawerComponent from './ChangeScheduledTimeDrawerComponent.js';
import AudioContextManager from '../../components/AudioContextManager.js';

const EVENT_START_THRESHOLD_SECONDS = 10;
const EVENT_NOTIFICATION_DELAY = 0; // delay in milliseconds

const PyroPage = ({ baseUrl, audioContext }) => {
  const { eventId } = useParams();
  const { t } = useTranslation();

  const [audioContextState, setAudioContextState] = useState(
    audioContext.state
  );
  const { wakeLockActive, enableWakeLock, disableWakeLock } = useWakeLock();
  const [isTestMode, setisTestMode] = useState(true); // Per default is in test
  // const [testDisable,setTestDisable] = useState(false); // Disable test mode when < 10 min before start of event

  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(
    audioContext.state === 'suspended'
  );
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const { mode, setMode: setMaterialMode } = useMaterialColorScheme();
  const { setMode: setJoyMode } = useJoyColorScheme();

  // Fetch event information
  const { eventInfo, scheduledTime, testTime, error, isEventPassed } =
    useEventInfo(baseUrl, `pyro/${eventId}`);

  // Test mode is only synchronizing between Pyropages, allows the organisator to test the music & synchronization
  const { ntpOffset, isSynchronized, playbackDelta, deltaScheduledWServer } =
    useNTPTime(
      baseUrl,
      audioContext,
      audioContextState,
      isTestMode ? testTime : scheduledTime
    );

  const musicPath = eventInfo?.musicPath ?? '';
  const isTimecode = eventInfo?.isTimecode ?? false;
  const timecodeChannel = eventInfo?.timecodeChannel ?? 0;

  const { isMusicPlaying, isMusicFinished, toggleMute, isMuted, isMusicError } =
    useEventAudio(
      musicPath,
      audioContext,
      playbackDelta,
      deltaScheduledWServer,
      isTimecode,
      timecodeChannel,
      true
    );
  const { isNotificationGranted, requestPermission, notify } =
    useNotification();

  const toggleDrawer = open => () => {
    setIsDrawerOpen(open);
  };

  // Update Scheduled time in the database
  const updateEventInDatabase = async newScheduledTime => {
    try {
      // Update scheduled or test Time, does not update PublicLink
      const fieldToUpdate = isTestMode
        ? { testTime: newScheduledTime.toISOString() }
        : { scheduledTime: newScheduledTime.toISOString() };
      await axios.patch(`${baseUrl}/api/v1/events/${eventId}`, {
        ...fieldToUpdate,
        generatePublicLink: false,
      });
    } catch (err) {
      console.error('Error updating scheduled/test time:', err);
      alert(`Failed to update scheduled time: ${err.message}`);
    }
  };

  // Opens the modal automatically when the audioContext is suspended
  useEffect(() => {
    setIsWelcomeModalOpen(audioContext.state === 'suspended');
    setAudioContextState(audioContext.state);
  }, [audioContext.state]);

  // Resume audio context when modal is closed
  const handleAudioContextState = async () => {
    if (audioContext.state === 'suspended') {
      audioContext.resume().then(() => {
        setIsWelcomeModalOpen(false);
        setAudioContextState(audioContext.state);
      });
    }
    return false;
  };

  // Resume audio context when modal is closed and ask for permission & activate
  const handleAudioAndNotification = async () => {
    // Handle Audio Context State:
    await handleAudioContextState();
    // Request permission to launch notification
    console.log('Requesting Permission');
    await requestPermission();
    return false;
  };

  // To switch to public mode whenever the music is already started and 10 secondes before start of event
  useEffect(() => {
    if (!scheduledTime) return;
    const adjustedServerTime = new Date(Date.now() + ntpOffset);
    const deltaScheduledWServer =
      (new Date(scheduledTime).getTime() - adjustedServerTime.getTime()) / 1000;
    if (deltaScheduledWServer > EVENT_START_THRESHOLD_SECONDS) {
      const timeoutDuration =
        (deltaScheduledWServer - EVENT_START_THRESHOLD_SECONDS) * 1000; // in ms
      const timeoutId = setTimeout(() => {
        if (isTestMode) {
          setisTestMode(false);
          setIsSnackBarOpen(true);
        }
        if (isNotificationGranted && !isTestMode) {
          console.log('Notifying!');
          notify(
            t('notificationTitle'),
            { body: t('notificationDescription') },
            EVENT_NOTIFICATION_DELAY
          );
        }
      }, timeoutDuration);

      return () => clearTimeout(timeoutId);
    } else {
      setisTestMode(false);
    }
  }, [scheduledTime, ntpOffset, isNotificationGranted]);

  return (
    <>
      <AudioContextManager
        audioContext={audioContext}
        setAudioContextState={setAudioContextState}
        setIsWelcomeModalOpen={setIsWelcomeModalOpen}
      />
      <WelcomeModalComponent
        open={isWelcomeModalOpen}
        onHandleAudioAndNotification={handleAudioAndNotification}
        onHandleAudioContext={handleAudioContextState}
        pyro={true}
      />
      <Box
        sx={{
          height: '100vh',
          height: '100dvh',
          maxWidth: '1330px',
          mx: 'auto',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme =>
            isTestMode
              ? mode === 'dark'
                ? theme.palette.warning[500]
                : theme.palette.warning[50]
              : theme.palette.background.body,
        }}
      >
        <CssBaseline />
        <Tabs
          aria-label="test-mode"
          value={isTestMode}
          onChange={(event, value) => {
            setisTestMode(value);
            setIsSnackBarOpen(true);
          }}
          sx={{ width: '100%', position: 'sticky', top: 0, zIndex: 10 }}
        >
          <TabList variant="plain" sx={{ width: '100%' }}>
            <Tab
              color={isTestMode ? 'warning' : 'primary'}
              variant={isTestMode ? 'soft' : 'outlined'}
              sx={{ width: '100%' }}
              value={true}
            >
              <span className="material-symbols-outlined" sx={{ mx: 2 }}>
                build
              </span>
              {t('testMode')}
            </Tab>
            <Tab
              color={isTestMode ? 'primary' : 'primary'}
              variant={isTestMode ? 'outlined' : 'soft'}
              sx={{ width: '100%' }}
              value={false}
            >
              <span className="material-symbols-outlined" sx={{ mx: 2 }}>
                groups
              </span>
              {t('publicMode')}
            </Tab>
          </TabList>
        </Tabs>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            textAlign: 'left',
            flexGrow: 1,
            p: 2,
          }}
        >
          {/* Event Info */}
          <Box sx={{ textAlign: 'left', width: '100%' }}>
            {/* Show placeholder message when eventPassed */}
            {eventInfo && eventInfo.imgPath && eventInfo?.isPassed && (
              <Box
                component="img"
                src={eventInfo.imgPath}
                alt={`Image representing ${eventInfo ? eventInfo.name : 'event'}`}
                sx={{
                  width: '100%',
                  maxHeight: '33vh',
                  display: 'block',
                  borderRadius: 'md',
                  objectFit: 'cover',
                  border: 1,
                  borderColor: 'divider',
                }}
              />
            )}
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography level="h1">
                {eventInfo ? eventInfo.name : t('loading')}
              </Typography>
              <Dropdown>
                <MenuButton variant="plain">
                  <span className="material-symbols-outlined">more_vert</span>
                </MenuButton>
                <Menu placement="bottom-end" sx={{ zIndex: 30 }}>
                  <MenuItem
                    sx={{ fontSize: '1.5rem' }}
                    onClick={() => setShareModalOpen(true)}
                  >
                    <span className="material-symbols-outlined">share</span>
                    {t('share')}
                  </MenuItem>
                  <MenuItem
                    sx={{ fontSize: '1.5rem' }}
                    onClick={() =>
                      wakeLockActive ? disableWakeLock() : enableWakeLock()
                    }
                  >
                    <span className="material-symbols-outlined">
                      {wakeLockActive ? 'lock_open' : 'lock'}
                    </span>
                    {wakeLockActive
                      ? t('pyroScreenUnLock')
                      : t('pyroScreenLock')}
                  </MenuItem>
                  <MenuItem
                    sx={{ fontSize: '1.5rem' }}
                    onClick={() => {
                      setMaterialMode(mode === 'dark' ? 'light' : 'dark');
                      setJoyMode(mode === 'dark' ? 'light' : 'dark');
                    }}
                  >
                    <span className="material-symbols-outlined">
                      {mode === 'dark' ? 'light_mode' : 'dark_mode'}
                    </span>
                    {mode === 'dark'
                      ? t('actionTurnLightmode')
                      : t('actionTurnDarkmode')}
                  </MenuItem>
                </Menu>
              </Dropdown>
            </Box>
            {(error || eventInfo?.isPassed) && (
              <Typography>
                {eventInfo ? eventInfo.description : t('loading')}
              </Typography>
            )}
            {!error && !eventInfo?.isPassed && (
              <Box sx={{ textAlign: 'left', width: '100%' }}>
                <Typography>
                  {' '}
                  {scheduledTime
                    ? t('pyroScheduledTime', {
                        scheduledTime: scheduledTime.toLocaleString(),
                      })
                    : t('loading')}
                </Typography>
                {/* <Typography >Delta w Server: {ntpOffset !== null ? `${ntpOffset} milliseconds` : t('loading')}</Typography> */}
                {/* <Button variant="soft" onClick={toggleAudioContext} startDecorator={<span className="material-symbols-outlined">replay</span>}>
              Change AudioContext
            </Button> */}
              </Box>
            )}
          </Box>

          {/* Countdown*/}
          {!error && !eventInfo?.isPassed && (
            <CountdownComponent
              ntpOffset={ntpOffset}
              scheduledTime={isTestMode ? testTime : scheduledTime}
            />
          )}

          {/* Change scheduled time, Powered, musicplayer*/}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
              width: '100%',
            }}
          >
            <Button onClick={toggleDrawer(true)} variant="outlined">
              {isTestMode ? t('changeTestTime') : t('changeScheduledTime')}
            </Button>
            <BrandFooterComponent mode={mode} />
            {/* Music Player conditionnaly rendering depending on error state */}
            {!error && (
              <>
                {eventInfo && eventInfo.isTimecode ? (
                  <Sheet
                    color="warning"
                    variant="soft"
                    sx={{ p: 2, borderRadius: 'md', width: '100%' }}
                  >
                    <Typography>{t('pyroTimecode')}</Typography>
                    {eventInfo.timecodeChannel ? (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            minWidth: 0,
                          }}
                        >
                          <span className="material-symbols-outlined">
                            speaker
                          </span>
                          <Typography>{`L : ${t('music')}`}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            minWidth: 0,
                          }}
                        >
                          <span className="material-symbols-outlined">
                            speaker
                          </span>
                          <Typography>{`R : ${t('timecode')}`}</Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            minWidth: 0,
                          }}
                        >
                          <span className="material-symbols-outlined">
                            speaker
                          </span>
                          <Typography>{`L : ${t('timecode')}`}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            minWidth: 0,
                          }}
                        >
                          <span className="material-symbols-outlined">
                            speaker
                          </span>
                          <Typography>{`R : ${t('music')}`}</Typography>
                        </Box>
                      </Box>
                    )}
                  </Sheet>
                ) : null}
                {/* MusicPlayer */}
                <Box
                  sx={{
                    width: '100%',
                    position: 'sticky',
                    bottom: 0,
                    zIndex: 10,
                  }}
                >
                  <MusicPlayerComponent
                    isEventPassed={isEventPassed}
                    isSynchronized={isSynchronized}
                    isMusicPlaying={isMusicPlaying}
                    isMusicFinished={isMusicFinished}
                    isMuted={isMuted}
                    isMusicError={isMusicError}
                    scheduledTime={isTestMode ? testTime : scheduledTime}
                    toggleMute={toggleMute}
                  />
                </Box>
              </>
            )}
          </Box>
          <ChangeScheduledTimeDrawerComponent
            isDrawerOpen={isDrawerOpen}
            toggleDrawer={toggleDrawer}
            updateEvent={updateEventInDatabase}
            scheduledTime={isTestMode ? testTime : scheduledTime}
            ntpOffset={ntpOffset}
          />
        </Box>
      </Box>
      {/* </Box> */}
      {/* QRCode Share component */}
      {eventInfo && (
        <QRCodeModalComponent
          open={shareModalOpen}
          handleClose={() => setShareModalOpen(false)}
          eventData={eventInfo}
          isPyroPage={true}
          size={256}
        />
      )}
      <Snackbar
        variant="soft"
        color="warning"
        open={isSnackBarOpen}
        sx={{ fontSize: '1.5rem' }}
        onClose={(event, reason) => {
          if (reason === 'clickaway') {
            return;
          }
          setIsSnackBarOpen(false);
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Box flexDirection="column">
          {isTestMode ? t('testModeSnackbar') : t('publicModeSnackbar')}
          <Button
            onClick={() => setIsSnackBarOpen(false)}
            size="md"
            variant="soft"
            color="warning"
          >
            {t('confirm')}
          </Button>
        </Box>
      </Snackbar>
    </>
  );
};

export default PyroPage;
