import React from 'react';
import Box from '@mui/joy/Box';
import FormLabel from '@mui/joy/FormLabel';
import Stack from '@mui/joy/Stack'
import Typography from '@mui/joy/Typography';
import Tooltip from '@mui/joy/Tooltip';
import { useTranslation } from 'react-i18next';


const CustomFormLabel = ({htmlFor, label , optional, flyover, flyoverText }) => {
  const { t } = useTranslation(); 

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', zIndex: 999}}>
      <Stack direction='row' alignItems='center' spacing={1}>
        <FormLabel htmlFor={htmlFor}>{label}</FormLabel>
        {flyover && ( 
          <Tooltip title={t(flyoverText)} color="primary" placement="top" variant="outlined"> 
          <span className="material-symbols-outlined" style={{ fontSize: '14px' }}>info</span> 
          </Tooltip>)}
      </Stack>
      {optional && (
        <Typography component="span" sx={{ fontSize: 'small', color: 'text.secondary' }}>
          {t('optional')}
        </Typography>
      )}

    </Box>
  );
};

export default CustomFormLabel;