import React from 'react';

import { Typography,Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';

const BrandFooterComponent = ({ mode, showDate}) => {
    const { t } = useTranslation();

    return (
        <Box sx={{
            width: '100%',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
          }}>
            <img src={`/Logo_${mode}.svg`} alt="Orbiner Logo" style={{ height: '16px' }} />
            <Typography>{showDate? t('copyrightOrbiner', { date: new Date().getFullYear() }): t('copyrightOrbiner', {date:""})}</Typography>
            <Typography component="a" href="https://orbiner.com">{t('orbinerSite')}</Typography>
          </Box>
    );
};

export default BrandFooterComponent;