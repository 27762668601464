// components/WelcomeModalComponent.js
import React from 'react';
import { Modal, ModalDialog, DialogContent, Button } from '@mui/joy';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';

import { useTheme } from '@mui/joy/styles';
import { useTranslation } from 'react-i18next';

const WelcomeModalComponent = ({
  open,
  onHandleAudioContext,
  onHandleAudioAndNotification,
  pyro,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <>
      <Modal
        open={open}
        onClose={() => {}}
        aria-labelledby="welcome-modal-title"
        sx={{ backdropFilter: 'blur(10px)' }}
      >
        <ModalDialog
          variant="outlined"
          color="primary"
          sx={{
            maxWidth: '400px',
            width: 'auto',
            mx: 'auto',
            overflow: 'hidden',
            p: 0,
          }}
        >
          <DialogContent>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 'md',
              }}
            >
              <Box
                sx={{
                  overflow: 'hidden',
                  bgcolor: theme.palette.primary[50],
                  borderBottom: 1,
                  borderColor: 'divider',
                }}
              >
                <img
                  src="/welcome_illustration.png"
                  alt="Welcome"
                  style={{
                    width: '100%',
                    display: 'block',
                    margin: 0,
                    padding: 0,
                    maxWidth: '600px',
                  }}
                />
              </Box>
              <Box
                sx={{
                  p: '1.25em',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'left',
                }}
              >
                <Typography level="h3" id="welcome-modal-title">
                  {pyro
                    ? t('welcomeModalTitlePyro')
                    : t('welcomeModalTitlePublic')}
                </Typography>
                <Typography level="body2">{t('welcomeModalText')}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                px: '1.25em',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
              }}
            >
              <Typography
                level="body2"
                sx={{ fontSize: '0.75rem', mb: '0.5rem' }}
              >
                <a href="https://orbiner.com/terms">{t('terms')}</a>
              </Typography>
              <Button
                variant="soft"
                size="lg"
                onClick={onHandleAudioAndNotification}
                sx={{ alignSelf: 'stretch', mb: '1em' }}
              >
                {t('activateMusicAndNotification')}
              </Button>
              <Button
                variant="outlined"
                size="md"
                onClick={onHandleAudioContext}
                sx={{ alignSelf: 'stretch', mb: '1.25em' }}
              >
                {t('activateMusic')}
              </Button>
            </Box>
          </DialogContent>
        </ModalDialog>
      </Modal>
    </>
  );
};

export default WelcomeModalComponent;
