import React, {useEffect} from 'react';

const AudioContextManager = ({ audioContext, setAudioContextState, setIsWelcomeModalOpen }) => {
  // Handle audio context state updates
  useEffect(() => {
    setIsWelcomeModalOpen(audioContext.state === 'suspended');
    setAudioContextState(audioContext.state);
  }, [audioContext.state, setIsWelcomeModalOpen, setAudioContextState]);

  return <>{/* This component does not render anything; it only manages state */}</>;
};

export default AudioContextManager;

