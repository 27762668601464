import React, { useState, useEffect, useContext } from 'react';

import { parseISO } from 'date-fns';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { Modal,  ModalDialog, DialogTitle, DialogContent, Button, Input, Textarea, Stack, FormControl,  Typography, Box, Autocomplete, Sheet, Switch, LinearProgress, CircularProgress  } from '@mui/joy';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';



import { AuthContext } from '../../contexts/AuthContext';
import CustomFormLabel from '../../components/CustomFormLabel';
import {formatDate} from '../../utils/dateFormat'
import { cleanFilename } from '../../utils/fileUtils';



const EventModalComponent = ({ open, handleClose, baseUrl, isEditing, eventData}) => {
  const { t } = useTranslation(); 

  const [eventName, setEventName] = useState('');
  const [eventLocation, setEventLocation] = useState('');
  const [eventDescription, setEventDescription] = useState('');
  const [eventDate, setEventDate] = useState(null); 
  const [eventPrice, setEventPrice] = useState(null);
  const [musicFile, setMusicFile] = useState(null); // File is renamed (WAF)
  const [eventImageFile, setEventImageFile] = useState(null); // File is renamed (WAF)
  
  const CHUNK_SIZE = 1024 * 1024; // 1 MB
  const MAX_RETRIES = 3;  // Maximum number of retries per chunk
  const RETRY_DELAY = 2000;  // Delay between retries in milliseconds
  const [musicUploadProgress, setMusicUploadProgress] = useState(0);
  const [imageUploadProgress, setImageUploadProgress] = useState(0);

  const [isTimecode, setIsTimecode] = useState(false);
  const [timecodeChannel, setTimecodeChannel] = useState([]);

  const [generatePublicLink,  setGeneratePublicLink]=useState(false);
  const [isLocationSelected, setIsLocationSelected] = useState(false);

  const [citiesOption, setCitiesOption] = useState([]);  


  const [errorMessages, setErrorMessages] = useState({});

  const { userBusinessId } = useContext(AuthContext);
  const [checked, setChecked] = useState(false);


  const [isLoadingLocation, setIsLoadingLocation] = useState(false); 
  const [isLoadingUploadImage, setIsLoadingUploadImage] = useState(false);
  const [isLoadingUploadMusic, setIsLoadingUploadMusic] = useState(false);
  const [isLoadingEvent, setIsLoadingEvent] = useState(false);

  // Validate mandatory fields (event location and scheduled time)
  const isFormValid = isLocationSelected && musicFile && eventImageFile && eventDate != null;
  const finalErrorMessage = Object.values(errorMessages).join('\n');
  const [publicLinkWarningMessage, setPublicLinkWarningMessage] = useState(false);

  useEffect(() => {
    const fetchCities = async () => {
      if (!eventLocation) {
        setCitiesOption([]);
        return;
      }
      setIsLoadingLocation(true); // Start loading
      const apiUrl = `https://geo.api.gouv.fr/communes?nom=${eventLocation}&fields=nom,code,codeDepartement,codeRegion,population&limit=5`;

      try {
        const cities = await axios.get(apiUrl);
        setCitiesOption(cities.data);
      } catch (error) {
        console.error('Failed to fetch cities:', error);
        setCitiesOption([]);
      } finally {
        setIsLoadingLocation(false); // End loading
      }
    };
    // Debounce the API call
    const timer = setTimeout(() => {
      fetchCities();
    }, 500);
    return () => {
      clearTimeout(timer);
      setIsLoadingLocation(false);
    } 
  }, [eventLocation]);

  // Pre-fill fields when in edit mode
  useEffect(() => {
    // Launch each-time the modale opens
    if (isEditing && eventData) {
      setEventName(eventData.name);
      setEventDescription(eventData.description);
      setEventLocation(eventData.location);
      setEventDate(parseISO(eventData.scheduledTime));
      setEventImageFile({name:eventData.originalEventImageName})
      setMusicFile({name:eventData.originalMusicName})
      setIsLocationSelected(true);
      setIsTimecode(!!eventData.isTimecode);
      setTimecodeChannel(eventData.timecodeChannel);
      setCitiesOption([]);
      setErrorMessages({})
      setPublicLinkWarningMessage(false);
    } else {
      resetForm();
    }
  }, [isEditing, eventData, open]);

  const resetForm = () => {
    setEventName('');
    setEventDescription('');
    setEventLocation('');
    setEventDate(null);
    setEventImageFile(null);
    setMusicFile(null);
    setIsLocationSelected(false);
    setIsTimecode(false);
    setTimecodeChannel(false);
    setErrorMessages({})
    setCitiesOption([]);
    setPublicLinkWarningMessage(false);
    setGeneratePublicLink(false);
  };

  const handleCreateEvent = async (event) => {
    setIsLoadingEvent(true)
    event.preventDefault(); // Prevent form submission from reloading the page

    let uploadSuccess = true;

    if (musicFile) {
      setIsLoadingUploadMusic(true)
      const musicUploadSuccess = await uploadFile(musicFile,true);
      if (!musicUploadSuccess) {
        uploadSuccess = false;
      }
      setIsLoadingUploadMusic(false)
    }
    if (eventImageFile) {
      setIsLoadingUploadImage(true)
      const imageUploadSuccess = await uploadFile(eventImageFile,false);
      if (!imageUploadSuccess) {
        uploadSuccess = false;
      }
      setIsLoadingUploadImage(false)
    } 

    if (!uploadSuccess) {
      // Music has not been uploaded correctly
      setIsLoadingEvent(false);
      setErrorMessages(prev => ({...prev, ERROR_UPLOAD:t("unexpectedUploadError")}));
      return; 
    }
    const eventData = {
      name: eventName,
      description: eventDescription,
      scheduledTime: eventDate,
      location: eventLocation,
      businessId: userBusinessId,
      originalMusicName: musicFile.name,
      originalEventImageName: eventImageFile.name,
      isTimecode: isTimecode,
      timecodeChannel: timecodeChannel,
    };
    try {
      const response = await axios.post(`${baseUrl}/api/v1/events`, eventData,{ withCredentials: true });
      // console.log(response)
      resetForm(); 
      handleClose(); 
    } catch (error) {
      console.error("Failed to save event:", error);
      if (error.response?.data?.error === 'EVENT_IN_PAST'){
        setErrorMessages(prev => ({...prev, EVENT_IN_PAST:t("eventInPastError")}));
      } else if (error.response?.data?.error === 'EVENT_ALREADY_EXISTS') {
        setErrorMessages(prev => ({...prev, EVENT_ALREADY_EXISTS: 
          t('eventAlreadyExists', {
            location: error.response.data.details.location,
            date: formatDate(error.response.data.details.date)})
      })); 
      }  
    }finally {
      setIsLoadingEvent(false)
    }
  }

  const handleUpdateEvent = async (event) => {
      setIsLoadingEvent(true)
      event.preventDefault(); // Prevent form submission from reloading the page
      let updatedFields = {};
      if (eventName !== eventData.name) {
        updatedFields.name = eventName;
      }
      if (eventLocation !== eventData.location) {
        updatedFields.location = eventLocation;
      }
      if (eventDescription !== eventData.description) {
        updatedFields.description = eventDescription;
      }
      if (eventDate && eventDate.toISOString() !== eventData.scheduledTime) {
        updatedFields.scheduledTime = eventDate.toISOString();
      }
      if (musicFile && musicFile.name !== eventData.originalMusicName) {      
        setIsLoadingUploadMusic(true)  
        const musicUploadSuccess = await uploadFile(musicFile,true);        
        if (musicUploadSuccess) {
        updatedFields.originalMusicName = musicFile.name;        
        }
        setIsLoadingUploadMusic(false)
      }

      if (eventImageFile && eventImageFile.name !== eventData.originalEventImageName) {
        setIsLoadingUploadImage(true)
        const imageUploadSuccess = await uploadFile(eventImageFile,false);
        if (imageUploadSuccess) {
        updatedFields.originalEventImageName = eventImageFile.name;}
        setIsLoadingUploadImage(false)
      }

      if (isTimecode !== eventData.isTimecode || timecodeChannel !== eventData.timecodeChannel) {
        updatedFields.isTimecode = isTimecode;
        updatedFields.timecodeChannel = timecodeChannel;
      }

      // To regenerate publicLink
      updatedFields.generatePublicLink = generatePublicLink;
      // console.log(updatedFields.generatePublicLink)

      if (Object.keys(updatedFields).length > 0) {
        try {
          const response = await axios.patch(`${baseUrl}/api/v1/events/${eventData.id}`, updatedFields, {withCredentials:true});
          // console.log("Event updated:", response.data);
          handleClose();
        } catch (error) {
          console.error("Failed to update event:", error);
          if (error.response?.data?.error === 'EVENT_IN_PAST'){
            setErrorMessages(prev => ({...prev, EVENT_IN_PAST: t("eventInPastError")}));
          } else if (error.response?.data?.error === 'EVENT_ALREADY_EXISTS') {
            setErrorMessages(prev => ({...prev, EVENT_ALREADY_EXISTS:          
              t('eventAlreadyExists', {
              location: error.response.data.details.location,
              date: formatDate(error.response.data.details.date)})
            })); 
          }    
        } finally {
          setIsLoadingEvent(false)
        }
      } else {
        setIsLoadingEvent(false)
        console.log("No changes to update");
        handleClose(); // Close the modal if no changes
      }
  };


  const handleGetPrice = async (eventLocation) => {    
    try {
      const response = await axios.get(`${baseUrl}/api/v1/events/price/${eventLocation}`, {withCredentials:true});
      setEventPrice(response.data.calculatedPrice/100)
    } catch (error) {
      console.error("Failed to get event price:", error);
    }
  }


  const uploadFile = async (file,isMusic) => {
    // Divide the file in chunks of 1mb for network
    if (!file) return;

    const totalChunks = Math.ceil(file.size / CHUNK_SIZE);
    const originalMimeType = file.type;  // Get the MIME type of the original file
    let start = 0;

    try {
      for (let index = 0; index < totalChunks; index++) {
          const end = Math.min(start + CHUNK_SIZE, file.size);
          const chunk = file.slice(start, end);
          // Upload the current chunk
          const chunkUploadSuccess = await uploadChunk(file.name,chunk, index, totalChunks, originalMimeType,isMusic);
          // const chunkUploadSuccess = true;
          if(!chunkUploadSuccess)
            {
              throw new Error('Network issue')
            }
            if (isMusic) // update linear progress bar
            {
              setMusicUploadProgress(index * 100 / totalChunks) 
            } else {
              setImageUploadProgress(index * 100 / totalChunks)
            }
          start = end;
      }
      setMusicUploadProgress(0) // reinitialize MusicUploadprogress
      setImageUploadProgress(0) // reinitialize ImageUploadprogress
      return true;  // Success
    }
    catch(error){
      console.log(error)
      if (error.response?.data?.error === 'UPLOAD_INVALID_FILE'){
        // Invalid file type
        if (isMusic) {setErrorMessages(prev => ({...prev, UPLOAD_MUSIC_INVALID_FILE: t("eventMusicFormatError")}));}
        else {setErrorMessages(prev => ({...prev, UPLOAD_IMG_INVALID_FILE: t("eventImageFormatError")}));}        
      } 
      else {setErrorMessages(prev => ({...prev, UPLOAD_FILE_ERROR: t("unexpectedMusicFormatError")}));
      }
      setMusicUploadProgress(0) // reinitialize MusicUploadprogress
      setImageUploadProgress(0) // reinitialize ImageUploadprogress
      return false; // Error
    } 
};

const uploadChunk = async (filename, chunk, chunkIndex, totalChunks,mimeType, isMusic) => {
    const formData = new FormData();
    formData.append("file", chunk);
    formData.append("index", chunkIndex);
    formData.append("total", totalChunks);
    formData.append('mimeType', mimeType);
    formData.append('originalName', filename);

    for (let attempt = 0; attempt < MAX_RETRIES; attempt++)  {// Retry 3 times if a chunk has not been uploaded correctly      
      try {
        // console.log(formData)
        const result = await axios.post(`${baseUrl}/api/v1/file/upload/${userBusinessId}?mimeType=${mimeType}&index=${chunkIndex}&originalName=${filename}`, formData, {
          headers: {'Content-Type': 'multipart/form-data',},withCredentials: true,});
          // console.log('Chunk uploaded:', result);
          // Show progress bar
          const progress = (chunkIndex / totalChunks) * 100;
          isMusic? setMusicUploadProgress(progress.toFixed(2)) : setImageUploadProgress(progress.toFixed(2))
          return true;  // Success
      } catch (error) {
            // Check for non-retriable error
          if (error.response?.data?.error === 'UPLOAD_INVALID_FILE') {
              throw error;  // Rethrow to break out of retry loop
          }
          if (attempt < MAX_RETRIES - 1) {
            console.log(`Retrying upload of chunk ${chunkIndex} in ${RETRY_DELAY}ms`);
            await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));  // Delay before next retry
        }
          console.error('Upload failed for chunk', chunkIndex, error);
      }
   }
   return false // Retry amount exceeded
};

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="event-modal-title"
      aria-describedby="event-modal-description"
      sx={{ backdropFilter: 'blur(10px)' }}>
      <ModalDialog 
        variant="outlined"
        color="primary"
      >
        <DialogTitle>
          {isEditing ? 'Editer l\'évènement' : 'Créer un nouvel évènement'}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={isEditing ? handleUpdateEvent : handleCreateEvent}>
            <Stack spacing={2}>
              <FormControl>
              <CustomFormLabel htmlFor="event-location" label={t('city')} optional={false} flyover={true} flyoverText={'cityDescription'} />
                <Autocomplete
                  freeSolo
                  inputValue={eventLocation}
                  onInputChange={(_, newInputValue, reason) => {
                    if (reason === 'input') {
                      setEventLocation(newInputValue);
                      setIsLocationSelected(false);
                      setErrorMessages(prev => ({...prev, locationValid: 'La ville selectionnée n\'est pas valide'}));
                    }
                    }}
                  onChange={(_, newValue) => {
                    if (newValue && typeof newValue === 'object' && newValue.nom) {
                      // Update event location with selected city's name
                      const newLocation = newValue.nom;
                      setEventLocation(newLocation); 
                      setEventName(newLocation);
                      // console.log('Getting price')
                      handleGetPrice(newLocation); // Calling price calculation
                      setIsLocationSelected(true);
                      setErrorMessages(prev => {
                        const updatedErrors = {...prev};
                        delete updatedErrors.locationValid; 
                        return updatedErrors;
                      });
                    }else {
                      setIsLocationSelected(false);
                      setErrorMessages(prev => ({...prev, locationValid: t('cityError')}));
                    }
                    if (isEditing) {
                      setPublicLinkWarningMessage(true);
                    }
                  }}
                  options={citiesOption}
                  getOptionLabel={(option) => option.nom}
                  loading={isLoadingLocation}
                  required
                  endDecorator={
                    isLoadingLocation ? (
                      <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />
                    ) : null
                  }
                  sx={{ minWidth: 300 }} 
                />
              </FormControl>
              <FormControl>
              <CustomFormLabel htmlFor="event-name" label={t('name')} optional={true} flyover={true} flyoverText={'nameDescription'} />
                  <Input
                    id="event-name"
                    value={eventName}
                    onChange={(e) => setEventName(e.target.value)}
                    variant="outlined"
                  />
              </FormControl>
              <FormControl>
                <CustomFormLabel htmlFor="event-description" label={t('description')} optional={true} flyover={false} />
                <Textarea
                  id="event-description"
                  value={eventDescription}
                  onChange={(e) => setEventDescription(e.target.value)}
                  minRows={4} 
                  maxRows={6} // nb of rows before scrolling
                />
              </FormControl>

              {/* Image upload */}
              <FormControl >
              <CustomFormLabel htmlFor="event-image" label={t('uploadImage')} optional={false} flyover={true} flyoverText={'imageDescription'} />
                <Button component="label" variant="plain" color="neutral" sx={{ justifyContent: 'flex-start' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', minWidth:0}}>
                    {isLoadingUploadImage ? (
                      <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />) : 
                      <span className="material-symbols-outlined" style={{ paddingRight: '8px' }}>image</span>
                    }                  
                  <Typography>{eventImageFile ? `| ${eventImageFile.name}` : '| Fichier Image'}</Typography>
                </Box>
                  <input type="file" hidden onChange={(e) => {
                    const file = e.target.files[0];
                    setErrorMessages(prev => {
                      const newMessages = {...prev};
                      delete newMessages.UPLOAD_IMG_FILE_TOO_BIG;
                      delete newMessages.UPLOAD_IMG_INVALID_FILE;
                      return newMessages;
                    });
                    setEventImageFile(null)
                    if (file) {
                    const fileSizeMB = file.size / 1024 / 1024; 
                    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
                    if (fileSizeMB > 200) {
                      setErrorMessages(prev => ({...prev, UPLOAD_IMG_FILE_TOO_BIG:t("eventImageFileSize")}));
                    } else if (!allowedTypes.includes(file.type)) {
                      setErrorMessages(prev => ({...prev, UPLOAD_IMG_INVALID_FILE:t("eventImageFormatError")}));
                    } else {
                      setEventImageFile(cleanFilename(file)); 
                    } 
                  }}} />
                </Button>
                { isLoadingUploadImage ? (   
                  <LinearProgress size="md" variant="outlined" color="primary" determinate value={imageUploadProgress}/>): <></> }
              </FormControl>

              <FormControl >
              <CustomFormLabel htmlFor="event-date" label={t('date')} optional={false} flyover={true} flyoverText={'dateDescription'} />
                <MobileDateTimePicker 
                    label=" "
                    value={eventDate}
                    onChange={(newValue) => {
                      setEventDate(newValue);
                      if (isEditing) {
                        setPublicLinkWarningMessage(true);
                      }
                    }}
                    minDate={new Date()}
                  />
              </FormControl>
            {/* Music upload */}
              <FormControl>
              <CustomFormLabel htmlFor="event-music" label={t('uploadMusic')} optional={false} flyover={true} flyoverText={'musicDescription'} />
                  <Button component="label" variant="soft" color="primary" sx={{ justifyContent: 'flex-start' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', minWidth:0}}>    
                        { isLoadingUploadMusic ? (
                          <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />) : 
                          <span className="material-symbols-outlined" style={{ paddingRight: '8px' }}>upload_file</span>}                      
                      <Typography>{musicFile ? `| ${musicFile.name}` : '| Fichier musique'}</Typography>
                    </Box>                     
                    <input type="file" hidden onChange={(e) => {
                        const file = e.target.files[0];
                        setMusicFile(null); // reset musicFile
                        setErrorMessages(prev => {
                          const newMessages = {...prev};
                          delete newMessages.UPLOAD_MUSIC_FILE_TOO_BIG;
                          delete newMessages.UPLOAD_MUSIC_INVALID_FILE;
                          return newMessages;
                        });
                        if (file) {
                          setMusicFile(null);
                          const fileSizeMB = file.size / 1024 / 1024; 
                          const allowedTypes = ['audio/wav', 'audio/mpeg'];
                          if (fileSizeMB > 200) {
                            setErrorMessages(prev => ({...prev, UPLOAD_MUSIC_FILE_TOO_BIG:t("eventMusicFileSize")}));
                          } else if (!allowedTypes.includes(file.type)) {
                            console.log(file.type)
                            setErrorMessages(prev => ({...prev, UPLOAD_MUSIC_INVALID_FILE:t("eventMusicFormatError")}));
                          } else {
                            setMusicFile(cleanFilename(file)); 
                          } 
                        }}} />
                  </Button> 
                  { isLoadingUploadMusic ? (   
                  <LinearProgress size="md" variant="outlined" color="primary" determinate value={musicUploadProgress}/>): <></> }          
              </FormControl>
              {/* TimeCode option */}
              <Box>
                <FormControl>
                  <CustomFormLabel htmlFor="event-timecode" label={t('timecode')} optional={true} flyover={true} flyoverText={'timecodeDescription'} />
                  <Typography component="label" sx={{ flexGrow: 1, textAlign: 'left' }} startDecorator={
                    <Switch variant={checked ? 'solid' : 'outlined'} 
                        onChange={(e) => {setIsTimecode(e.target.checked)
                          setChecked(e.target.checked)}}
                      checked={isTimecode} 
                      />
                    }>
                      {t('timeCode')}
                  </Typography>
                </FormControl>
                {isTimecode && 
                (<FormControl>
                  <CustomFormLabel htmlFor="event-timecodechannel" label={t('timecodeChannel')} optional={false} flyover={true} flyoverText={'timecodeChannelDescription'} />
                    <Switch variant='solid' 
                      onChange={(e) => {setTimecodeChannel(e.target.checked)}}
                      checked={timecodeChannel} 
                      startDecorator={
                        <Box sx={{ display: 'flex', alignItems: 'center', minWidth:0}}> 
                          <Typography component="label">
                          <span className="material-symbols-outlined">speaker</span> 
                          </Typography>
                          <Typography component="label">L</Typography>                          
                        </Box>}
                      endDecorator={
                        <Box sx={{ display: 'flex', alignItems: 'center', minWidth:0}}> 
                          <Typography component="label">
                          <span className="material-symbols-outlined">speaker</span> 
                          </Typography>
                          <Typography component="label">R</Typography>                          
                        </Box>}                  
                      />
                </FormControl>)}
              </Box>
              {publicLinkWarningMessage && (
                  <Sheet color="warning" variant="soft" sx={{ maxWidth: '450px' , p: 2, borderRadius: 'sm', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 1 }}>
                  <Typography color="warning" sx={{ textAlign: 'left' }}>
                    {t('updatePublicLink')}
                  </Typography>
                  <Typography color="warning" component="label" sx={{ flexGrow: 1, textAlign: 'left' }} endDecorator={
                  <Switch variant="solid" 
                    onChange={(e) => setGeneratePublicLink(e.target.checked)}
                    checked={generatePublicLink} 
                    sx={{ ml: 1 }} />
                  }>
                    {t('generatePublicLink')}
                  </Typography>
                </Sheet>
              )}
              {finalErrorMessage && (
                  <Sheet color="danger" variant="soft" sx={{ px: 2, py: 1, borderRadius: 'sm' }}>
                  {finalErrorMessage.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </Sheet>)}
                {eventPrice && (
                  <Sheet color="primary" variant="solid" sx={{ px: 2, py: 1, borderRadius: 'sm' }}>
                  {t('eventPriceInformation',{eventPrice})}
                </Sheet>)}
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button 
                  variant="plain" 
                  color="neutral" 
                  disabled={isLoadingEvent}  // to disable when loading
                  onClick={handleClose}>                  
                    {t('cancel')}
                  </Button>
                  <Button 
                    type="submit" 
                    variant="outlined" 
                    disabled={!isFormValid || isLoadingEvent} 
                    startDecorator={ isLoadingEvent ? (
                    <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />
                  ) : null}>
                    {isEditing ? t('save') : t('create')}
                  </Button>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default EventModalComponent;