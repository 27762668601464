// PublicPage.js
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom'; 
import { useTranslation } from 'react-i18next'; // Text


// Joy Ui library
import {CssBaseline,  Box, Typography, IconButton} from '@mui/joy';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import { useTheme } from '@mui/joy';
import { useColorScheme as useJoyColorScheme } from '@mui/joy/styles';
import { useColorScheme as useMaterialColorScheme } from '@mui/material/styles';

// Custom Hooks
import useEventInfo from '../useEventInfo'
import useNTPTime from '../useNTPTime'; 
import useEventAudio from '../useEventAudio.js'
import useWakeLock from '../useWakeLock.js'


// Custom components
// import MusicPlayer from '../components/MusicPlayer'; 
import MusicPlayerComponent from '../components/MusicPlayerComponent'; 
import WelcomeModalComponent from '../components/WelcomeModalComponent'
import QRCodeModalComponent from '../components/QRCodeModalComponent';
import BrandFooterComponent from '../components/BrandFooterComponent.js';


const PublicPage = ({baseUrl, audioContext}) => {
  const theme=useTheme();
  const { publicLink } = useParams();
  const { t } = useTranslation(); 

  const wakeLockRef = useRef(null);
  const { mode, setMode: setMaterialMode } = useMaterialColorScheme();
  const { setMode: setJoyMode } = useJoyColorScheme();
  // Get Event information from API & construct Music path

  // const [error, setError] = useState(null);
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(audioContext.state === 'suspended');
  const [shareModalOpen, setShareModalOpen]= useState(false);

  const [audioContextState, setAudioContextState] = useState(audioContext.state)
  const {wakeLockActive, enableWakeLock, disableWakeLock} = useWakeLock();

   
  const { eventInfo,scheduledTime, error, isEventPassed } = useEventInfo(baseUrl, `public/${publicLink}`);
  const { isSynchronized, playbackDelta, deltaScheduledWServer} = useNTPTime(baseUrl, audioContext, audioContextState, scheduledTime);

  const [isNetworkError, setIsNetworkError]=useState(false);
  const safeMusicPath = eventInfo?.musicPath || null;
  const safeIsTimecode = eventInfo?.isTimecode || false;
  const safeTimecodeChannel = eventInfo?.timecodeChannel || 0;

  const  {isMusicPlaying, isMusicFinished, toggleMute, isMuted, isMusicError} = useEventAudio(safeMusicPath, audioContext, playbackDelta, deltaScheduledWServer, safeIsTimecode, safeTimecodeChannel, false)
  


  // CHange text color depending on the mode
  const textColor = mode === 'dark' ? theme.palette.primary[50] : theme.palette.primary[500]
  // Opens the modal automatically when the audioContext is suspended
  useEffect(() => {
    setIsWelcomeModalOpen(audioContext.state === 'suspended');
    setAudioContextState(audioContext.state);
  }, [audioContext.state]);

  // Resume audiocontext when modal is closed
  const handleAudioContextState = () => {
    if (audioContext.state === 'suspended') {
      audioContext.resume().then(() => {
        setIsWelcomeModalOpen(false); // Close modal upon successful resume
        setAudioContextState(audioContext.state);
      });
    }
  };

  
  useEffect(() => {
    // Checking network issue
    const timeoutId = setTimeout(() => {
      if (!isSynchronized) {
        setIsNetworkError(true); 
      }
    }, 60000); 

    return () => clearTimeout(timeoutId);
  }, [isSynchronized]); 


  return (
    <>
    <WelcomeModalComponent
      open={isWelcomeModalOpen}
      onHandleAudioContext={handleAudioContextState}
      pyro={false}
    />
    <CssBaseline />
    <Box 
      sx={{
      height: '100vh',
      height: '100dvh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start', // space-between
      textAlign: 'left',
      width:'100%',
      maxWidth: '640px',
      mx: 'auto',
      p: 2,
    }}>

      {/* Event Image */}
      {eventInfo && eventInfo.imgPath && (<Box component="img"
        src={eventInfo.imgPath} 
        alt={`Image representing ${eventInfo ? eventInfo.name : 'event'}`}
        sx={{
          width: '100%',
          maxHeight: '33vh',
          minHeight: '20vh',
          display: 'block',
          borderRadius: 'md',
          objectFit: 'cover',
          border: 1,
          borderColor: 'divider'
        }}
      />
      )}
    <Box sx={{
      flex: 1, // Allows box to expand and fill available space, but not more
      width: '100%',
      // overflow: 'hidden', // Ensure this Box doesn't cause expansion beyond its limits
      position: 'relative',
      pb: 1,
    }}>
      <Box sx={{
        overflowY: 'auto',
               '&::-webkit-scrollbar': { display: 'none' }, 
        msOverflowStyle: 'none', 
        scrollbarWidth: 'none', 
        pb:4 // Avoid cutting the last sentence
      }}>
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems:'center'}}>
            <Typography level="h1" sx={{ mt: 1, color: textColor }}>{eventInfo ? eventInfo.name : t('loading')}</Typography>
            <Box>
              <IconButton onClick={() => setShareModalOpen(true)} className="action-icons" >
                <span className="material-symbols-outlined">share</span> 
              </IconButton>
              <Dropdown>
              <MenuButton variant="plain">
                <span className="material-symbols-outlined">more_vert</span>
              </MenuButton>
              <Menu placement="bottom-end" sx={{zIndex: 30}}>
                <MenuItem sx={{ fontSize: '1.5rem', }} onClick={() => wakeLockActive ? disableWakeLock() : enableWakeLock()}>  
                  <span className="material-symbols-outlined">{wakeLockActive ? 'lock_open': 'lock' }</span>  
                  {wakeLockActive ? t('pyroScreenUnLock') : t('pyroScreenLock')}
                </MenuItem>
                <MenuItem
                  sx={{ fontSize: '1.5rem', }}
                  onClick={() => {
                    setMaterialMode(mode === 'dark' ? 'light' : 'dark');
                    setJoyMode(mode === 'dark' ? 'light' : 'dark');
                  }}
                >
                  <span className="material-symbols-outlined">
                    {mode === 'dark' ? 'light_mode' : 'dark_mode'}
                  </span>
                  {mode === 'dark' ? t('actionTurnLightmode') : t('actionTurnDarkmode')}
                </MenuItem>
              </Menu>
            </Dropdown>
            </Box>
        </Box>
        {eventInfo && eventInfo.location !== eventInfo.name &&
          <Typography level="h6" sx={{ color: textColor }}>
            {eventInfo.location}
          </Typography>
        }
        <Typography sx={{ color: textColor, whiteSpace: 'pre-line' }}>
          {eventInfo ? eventInfo.description : t('loading')}
        </Typography>
      </Box>
      {/* Gradient fade effect */}
      {/* <Box sx={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right:0,
        width: '100%',
        height: '80px',
        backgroundImage: theme => {
          const rgb = theme.palette.background.body.replace(/^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i, (_, r, g, b) => `${parseInt(r, 16)}, ${parseInt(g, 16)}, ${parseInt(b, 16)}`);
          return `linear-gradient(to bottom, rgba(${rgb}, 0), rgba(${rgb}, 1))`;
        },        pointerEvents: 'none', // Ensures the gradient doesn't block interaction with the text
      }}/> */}
    </Box>
    

      {/* Gradient + Brand Footer + Musicplayer */}
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', width: '100%',position: 'sticky',pt:1, bottom: '0', zIndex: 10,
        backgroundColor:theme.palette.background.body
      }}>  
        <BrandFooterComponent mode={mode} showDate={false}/>
      {/* Music Player conditionnaly rendering depending on error state */}
      {!error && (
        // Regular content and partner footer
        <>
          {/* Music Player */}
          <Box sx={{ width: '100%', my: 2 }}>
            <MusicPlayerComponent
                isEventPassed ={isEventPassed}
                isSynchronized={isSynchronized}
                isNetworkError={isNetworkError}
                isMusicPlaying={isMusicPlaying}
                isMusicFinished={isMusicFinished}
                isMuted={isMuted}
                isMusicError={isMusicError}
                scheduledTime={scheduledTime}
                toggleMute={toggleMute}
              />            
          </Box>
        </>
      )}
      </Box>
      {eventInfo && <QRCodeModalComponent 
          open={shareModalOpen}
          handleClose={() => setShareModalOpen(false)}
          eventData = {eventInfo}     
          isPyroPage={false}
          size={256}     
        />}
      </Box>
    </>
  );
};

export default PublicPage;