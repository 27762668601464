import  React from 'react';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const TimePicker = ({ onTimeSet }) => {
  const [value, setValue] = React.useState(new Date());

  const handleChange = (newValue) => {
    setValue(newValue);
    onTimeSet(newValue);
    console.log("Date & Hour picked:" + newValue)
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3}>
        <DateTimePicker
          renderInput={(props) => <TextField {...props} />}
          label="Schedule Time"
          value={value}
          ampm={false}
          onChange={handleChange}
          minDate={new Date()}
        />
      </Stack>
    </LocalizationProvider>
  );
};

export default TimePicker;