
function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  function formatDateWHours(dateString) {

      const optionsDate = { year: 'numeric', month: '2-digit', day: '2-digit' };
      const optionsTime = { hour: '2-digit', minute: '2-digit', hour12: false };
    
      const date = new Date(dateString);
      const formattedDate = date.toLocaleDateString(undefined, optionsDate);
      const formattedTime = date.toLocaleTimeString(undefined, optionsTime);
    
      return `${formattedDate} - ${formattedTime}`;
    
  }
  
  function formatHour(dateString) {
      const date = new Date(dateString);
      let hours = date.getHours();
      let minutes = date.getMinutes();
    
      // Padding the hours and minutes with leading zeros if necessary
      hours = hours < 10 ? '0' + hours : hours;
      minutes = minutes < 10 ? '0' + minutes : minutes;
    
      return `${hours}:${minutes}`;    
  }

  export {formatDate, formatDateWHours,formatHour};