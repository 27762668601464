import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Text

import SideBarComponent from "../../components/SideBarComponent.js"

import UsersTable from "./UsersTable.js";
import UserModalComponent from './UserModalComponent';

import { CssBaseline, Box, Button } from '@mui/joy';  // Use Joy UI components


const AdminUsersPage = ({baseUrl}) => {
  const { t } = useTranslation(); 

  const [users, setUsers] = useState([]);
  const [businesses, setBusinesses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [userModalOpen, setUserModalOpen] = useState(false);

  useEffect(() => {
      const fetchAllUsers = async () => {
        try {
          console.log('fetching users!')
          // Get all Users
          const usersResponse = await axios.get(`${baseUrl}/api/v1/users/`,{ withCredentials: true }); 
          
          setUsers(usersResponse.data);
          // console.log(`usersResponse:`, usersResponse);
          // console.log(usersResponse);
          // get all Businesses
          const businessResponse = await axios.get(`${baseUrl}/api/v1/businesses/`,{ withCredentials: true });      
          // console.log(businessResponse.data.data)      
          setBusinesses(businessResponse.data);
          
        } catch (error) {
          console.error('Failed to fetch all users:', error);
          setError(error);
        } finally {
          setLoading(false);
        }
      };
      fetchAllUsers();

  }, [baseUrl]);



  // Function to close the modal
  const handleCreateUser = () => {
    setIsEditing(false)
    setUserModalOpen(true)
    console.log("Add User clicked");
  };

  // Placeholder function for editing user
  const handleEditUser = (selectedUserId) => {
    setIsEditing(true)
    setUserModalOpen(true); // Open the modal
    // console.log(`Edit User ID: ${selectedUserId}`);
    // const userToEdit = users.find(user => user.id === selectedUserId);
    // setSelectedUser(userToEdit); // Set the selected user
  };

  const handleDeleteUser = async (selectedUserId) => {
    // console.log(`Delete User ID: ${selectedUserId}`);
    await axios.delete(`${baseUrl}/api/v1/users/${selectedUserId}`,{ withCredentials: true });
    setUsers(users.filter(user => user.id !== selectedUserId));
  
    // setIsEditing(true)
    // console.log(`Edit User ID: ${selectedUserId}`);
    // const userToEdit = users.find(user => user.id === selectedUserId);
    // setSelectedUser(userToEdit); // Set the selected user
  };

  // Function to close the modal
  const handleUserCloseModal = () => {
    setUserModalOpen(false);
  };
          
  return (
    <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <SideBarComponent baseUrl={baseUrl} settings={false}/> 
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        padding: '1em'
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '1em' }}>
        <h2>{t('users')}</h2>
        <Button onClick={handleCreateUser} variant="soft" color="primary">{t('createUser')}</Button>
      </Box>
      <Box className="admin-table">
      {users.length > 0 ? (
        <UsersTable 
          users={users} 
          onEditUser={handleEditUser}
          onDeleteUser={handleDeleteUser} 
        />) : (
          <div>{t('noUsers')}</div>
        )}
      </Box>
      <UserModalComponent 
        open={userModalOpen} 
        handleClose={handleUserCloseModal} 
        baseUrl={baseUrl}
        businesses={businesses}
      />
    </Box>
  </Box>
  );
};

export default AdminUsersPage;