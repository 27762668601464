import React from 'react';
import Table from '@mui/joy/Table';

import IconButton from '@mui/joy/IconButton';
import { useTranslation } from 'react-i18next';


const BusinessesTable = ({ businesses, onDeleteBusiness}) => {
  const { t } = useTranslation(); 
  return (
      <Table aria-label="users table" variant="outlined" color="primary" hoverRow>
        <thead >
          <tr>
            <th align="left">Name</th>
            <th align="left">Shortname</th>
            <th align="left">Stripe Id</th>
            <th align="left">{t('action')}</th>
          </tr>
        </thead>
        <tbody  sx={{borderRadius: '2em' }} >
          {businesses.map((business) => (
            <tr
              key={business.id}
              sx={{
                '&:last-child td, &:last-child th': { },
                '&:hover': {
                  '.action-icons': { // Class for action icons
                    visibility: 'visible', // Only show icons when row is hovered
                  }
                },
                '.action-icons': {
                  visibility: 'hidden', // Initially hide icons
                }
              }}
            >
              <td align="left">{business.name}</td>
              <td align="left">{business.shortname}</td>
              <td align="left">{business.stripeCustomerId}</td>
              <td align="right">
                {/* <IconButton onClick={() => onEditUser(user.id)} className="action-icons">
                  <EditIcon />
                </IconButton> */}
                <IconButton onClick={() => onDeleteBusiness(business.id)} className="action-icons">
                  <span className="material-symbols-outlined">delete</span>
                </IconButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
  );
};

export default BusinessesTable;