// PublicPage.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; 
import { useTranslation } from 'react-i18next'; // Text
import axios from 'axios';

import {CssBaseline,  Box, Typography, IconButton} from '@mui/joy';
import { useTheme } from '@mui/joy';

import EventCardStack from '../components/EventCardStack'
import { useColorScheme as useMaterialColorScheme } from '@mui/material/styles';


const BusinessEventsPage = ({baseUrl}) => {
  const theme = useTheme();
  const { businessShortName } = useParams();
  const { t } = useTranslation(); 
  const { mode} = useMaterialColorScheme();

  const [businessInfo, setBusinessInfo] = useState(null);
    // CHange text color depending on the mode
    const textColor = mode === 'dark' ? theme.palette.primary[50] : theme.palette.primary[500]

  const [events, setEvents] = useState(null);

  // Retrieve all events from business Short-Name 
    useEffect(() => {
        const fetchBusinessInformation = async () => {
        try {
            const response = await axios.get(`${baseUrl}/api/v1/businesses/adminName/${businessShortName}`,{ withCredentials: true });
            setBusinessInfo(response.data);
            console.log(response.data)
        } catch (error) {
            console.log(error);
        }
    }
    if (baseUrl) {
        fetchBusinessInformation();
      }
    }, [baseUrl, businessShortName]);

    // Retrieve all events from business Short-Name 
    useEffect(() => {
        const fetchBusinessEvents = async () => {
            try {
                const response = await axios.get(`${baseUrl}/api/v1/events/list/${businessShortName}`);
                const sortedEvents = response.data.sort((a, b) => new Date(a.scheduledTime) - new Date(b.scheduledTime));
                console.log(sortedEvents)
                setEvents(sortedEvents)
            } catch (error) {
                console.log(error);
            }
        }
        if (baseUrl) {
            fetchBusinessEvents();
        }

    }, [businessInfo]);



  return (
    <>
    <CssBaseline />
    <Box 
      sx={{
      height: '100vh',
      height: '100dvh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'left',
      justifyContent: 'space-between',
      textAlign: 'left',
      maxWidth: '1024px',
      mx:'auto',
      p: 2,
    }}>
     {/* Event Info */}
     {businessInfo && businessInfo.logoPath && (
      <Box component="img" 
        src={businessInfo.logoPath} 
        alt={`Image representing ${businessInfo ? businessInfo.name : 'business logo'}`} 
        sx={{ 
          mx:'auto',
          maxHeight: '64px',
          display: 'contain',  
        }}/>
        )}
     <Box sx={{ textAlign: 'left' }}>        

            <Typography level="h1" sx={{ mt: 1, color: textColor }}>{businessInfo ? businessInfo.name : t('loading')}</Typography>
            <Typography level="h6" sx={{ color: textColor }}>{t('eventList')}</Typography>
      </Box>
    {/* Event Cards Stack */}
    {events && <EventCardStack events={events} />}

      <Box sx={{ 
              mt: 'auto', 
              mb: '1em', 
              width: '100%', 
              textAlign: 'center',
              display: 'flex', 
              flexDirection: 'row', 
              justifyContent: 'center',
              alignItems: 'center', 
              gap: 1, 
            }}>
            <img src={`/Logo_${mode}.svg`} alt="Orbiner Logo" style={{ height: '24px' }} />
            <Typography>{t('copyrightOrbiner',{date:new Date().getFullYear()})}</Typography>
            <Typography component="a" href="https://orbiner.com">orbiner.com</Typography>
        </Box>
      </Box>
    </>
  );
};

export default BusinessEventsPage;