import React from 'react';

import {useTranslation } from 'react-i18next'; // Text



import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/joy/styles';
import {Typography, Box, Stack, Button } from '@mui/joy';
import { CssVarsProvider } from '@mui/joy/styles';
import { useColorScheme as useMaterialColorScheme } from '@mui/material/styles';




export default function EventPaymentErrorPage({baseUrl}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation(); 
  const { mode} = useMaterialColorScheme();

  const handleRedirectButton = async () => {
    navigate('/admin/events/');
  };


  return (
    <CssVarsProvider defaultMode="light" disableTransitionOnChange>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '100dvh',            
            justifyContent: 'space-between',
            maxWidth: '480px', width: 'auto',
            margin: 'auto',
            px: 2,}}>
          <Box component="header" sx={{py: 3,display: 'flex',alignItems: 'left',justifyContent: 'space-between',}}></Box>
            <Box component="main" sx={{
                    p: 2, 
                    borderRadius:'sm',border: '1px solid',borderColor: 'divider', backgroundColor: theme.palette.primary.outlinedBg, 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    width: '100%',
                    maxWidth: 'md', }}>           
              <Stack spacing={2} sx={{width:'100%'}}>
                {/* Image */}
                <Box component="img" src="/errorPayment.png" alt="Successful payment" sx={{
                  maxHeight: '30vh',
                  maxWidth: '100%', 
                  objectFit:'contain',
                  width: 'auto',    
                  height: 'auto'   
                }} />
                <Typography sx={{ textAlign: 'left' }}>
                  {t('errorPayment')}
                </Typography>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button 
                       variant="outlined" 
                       onClick={handleRedirectButton}>
                    {t('redirect')}
                  </Button>
                </Stack> 
              </Stack>
            </Box>
        {/* Footer */}
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
            <img src={`/Logo_${mode}.svg`} alt="Orbiner Logo" style={{ height: '12px', width: 'auto', alignSelf: 'start' }} /> © Orbiner {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
    </CssVarsProvider>
  );
}
