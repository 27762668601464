import React, { useState, useEffect } from 'react';

import { Typography, Stack, Box } from '@mui/joy';


const CountdownComponent = ({ ntpOffset, scheduledTime }) => {
    const [hoursDaysMessage, setHoursDaysMessage] = useState('')
    const [countDown, setCountDown] = useState("Loading...");

    useEffect(() => {
        let timerInterval;

        const updateCountdown = () => {
            const timeNow = Date.now();
            const ntpTime = new Date(timeNow + ntpOffset);
            const timeDifference = ntpTime.getTime() - scheduledTime.getTime();
            setCountDown(formatCountdown(timeDifference));
            adjustInterval(timeDifference);
        };
         
        // Adjust interval depending on the timedifference 
        const adjustInterval = (timeDifference) => {
            clearInterval(timerInterval);
            if (timeDifference >= -10000 && timeDifference <= 0) {
                // 10 seconds before the event it shows every 10 ms
                timerInterval = setInterval(updateCountdown, 10);
            } else {
                // Countdown every 1 seconds
                timerInterval = setInterval(updateCountdown, 1000);
            }
        };

        const formatCountdown = (timeDifference) => {
            // console.log(`Countdown : ${timeDifference}`)
            let sign = timeDifference < 0 ? "-" : timeDifference > 0 ? "\u00A0" : "\u00A0";
            let absTime = Math.abs(timeDifference);
            let minutes = Math.floor((absTime / (1000 * 60)) % 60);
            let seconds = Math.floor((absTime / 1000) % 60);
            let milliseconds = Math.floor(absTime % 1000);

            minutes = (minutes < 10) ? "0" + minutes : minutes;
            seconds = (seconds < 10) ? "0" + seconds : seconds;
            milliseconds = milliseconds < 100 ? (milliseconds < 10 ? "00" + milliseconds : "0" + milliseconds) : milliseconds;

            if (timeDifference >= -10000 && timeDifference <= 0) {
                return `${sign}${minutes}:${seconds}.${milliseconds}`;
            } else {
                return `${sign}${minutes}:${seconds}`;
            }
        };

        // initialize countdown
        if (ntpOffset !== null && scheduledTime !== null) {
            updateCountdown(); 
        }
        return () => clearInterval(timerInterval);
    }, [ntpOffset, scheduledTime]);

    // Showing Days & Hours on top of countdown
    useEffect(() => {
        if (ntpOffset !== null && scheduledTime !== null) {
            const timeNow = Date.now();
            const ntpTime = new Date(timeNow + ntpOffset);
            const deltaTime = ntpTime.getTime() - scheduledTime.getTime();
            setHoursDaysMessage(formatTime(-deltaTime))
            const setIntervalDaysandHours = setInterval(() => {
                const timeNow = Date.now();
                const ntpTime = new Date(timeNow + ntpOffset);
                const deltaTime = ntpTime.getTime() - scheduledTime.getTime();
                setHoursDaysMessage(formatTime(-deltaTime))
            }, 60000); // Check every 10 min
            return () => clearInterval(setIntervalDaysandHours);
        }
    }, [ntpOffset, scheduledTime]);

    // Format days & hours 
    const formatTime = (deltaTime) => {
        deltaTime = deltaTime / 1000;

        const days = Math.floor(deltaTime / (3600 * 24));
        const hours = Math.floor((deltaTime % (3600 * 24)) / 3600);

        let message = '';

        if (days > 0) {
            message += `-${days} jours et `;
        }
        if (hours > 0 || days > 0) {
            message += `-${hours} heures`;
        }
        return message;
    };


    return (
        <>
        <Box sx={{
          width: '100%',
          p: 2,
          textAlign: 'center',
        }}>
            <Typography sx={{
                // fontSize: 'xl', 
                lineHeight: '100%',
                fontSize: 'min(4em,10vw)', // Make font size responsive
                maxWidth: '100%',
                fontFamily: '"Roboto Mono", monospace',
                flexGrow: 1,
            }}>
                {hoursDaysMessage}
            </Typography>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                sx={{ width: '100%' }}
                >
                <Typography level="h1" sx={{
                    fontSize: 'min(8em,20vw)',
                    lineHeight: '150%',
                    maxWidth: '100%',
                    fontFamily: '"Roboto Mono", monospace',
                    textAlign: 'center',
                }}>
                    {countDown}
                </Typography>
            </Stack>
            </Box>
        </>
    );
};

export default CountdownComponent;