import React, { useState, useEffect } from 'react';
import { Modal,  ModalDialog, DialogTitle, DialogContent, Button, Input, Select, Stack, FormControl, FormLabel, Sheet, Option } from '@mui/joy';

import axios from 'axios';
import { useTranslation } from 'react-i18next';

const UserModalComponent = ({ open, handleClose, baseUrl, businesses}) => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [selectedBusiness, setSelectedBusiness] = useState('');
    
    const [errorMessage, setErrorMessage] = useState(null);
    const { t } = useTranslation(); 

    
    const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(email);
    };
    // Validate mandatory fields (email  and password)
    const isFormValid = email.trim() !== '' && password.trim() !== '' && isValidEmail(email)  && selectedBusiness && selectedBusiness.trim() !== ''; // Check if selectedBusiness is not null


    useEffect(() => {
      // Reset Form
      
      // Edit User not implemented yet
      // if (isEditing && userData) {
      //   setEmail(userData.email);
      //   setSelectedBusiness(userData.business);
      //   setErrorMessage(null);
      // } else {
        resetForm();
      // }
      // }
    // }, [isEditing, eventData, open]);
    }, [open]);


    const handleCreateUser = async (event) => {
      event.preventDefault(); 
      const userData = {
        email: email,
        password: password,
        businessId: selectedBusiness
      };

      try {
          console.log(userData);
          const response = await axios.post(`${baseUrl}/api/v1/auth/signup`, userData);
          console.log(response.data);
          resetForm(); 
          handleClose(); // Close the modal after the action    
        } catch (error) {
          console.error("Failed to create user:", error);

          // Check if the error response has data and error messages
          if (error.response && error.response.data && error.response.data.details.errors) {
              const messages = error.response.data.details.errors.map(err => err.msg || err);
              setErrorMessage(messages.join('\n'));
          } else {
              setErrorMessage(t('unexpectedError'));
          }
      }

    }

    const resetForm = () => {
        setEmail('');
        setPassword('');
        setSelectedBusiness('');
        setErrorMessage(null);
    };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="user-modal-title"
      aria-describedby="user-modal-description"
      sx={{ backdropFilter: 'blur(10px)'}}      
    >
      <ModalDialog 
        variant="outlined"
        color="primary"
        sx={{ overflow: 'hidden' }} // This ensures that the DialogContent does not have any additional space around it
      >
        <DialogTitle>
          {t('createUser')}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleCreateUser}>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel htmlFor="user-email">Email</FormLabel>
                  <Input
                    id="user-email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="user-password">{t('password')}</FormLabel>
                  <Input
                    id="user-password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
              </FormControl>
              <FormControl>
                <FormLabel htmlFor="user-business">{t('business')}</FormLabel>
                <Select
                  id="user-business"
                  value={selectedBusiness}
                  // onChange={(e) => setSelectedBusiness(e)}
                  onChange={(e, newValue) => setSelectedBusiness(newValue)}
                  label={t('selectBusiness')}
                >
                  {businesses.map((business) => (
                    <Option key={business.id} value={business.id} >
                      {business.name}
                    </Option>
                  ))}
                </Select>
              </FormControl>
                {errorMessage && (
                <Sheet color="danger" variant="soft" sx={{ px: 2, py: 1, borderRadius: 'sm' }}>
                  {errorMessage.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </Sheet>)}
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Button variant="plain" color="neutral" onClick={handleClose}>                  
                {t('cancel')}
                </Button>
                <Button type="submit" variant="outlined" disabled={!isFormValid} >
                {t('create')}
                </Button>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};
// 
export default UserModalComponent;