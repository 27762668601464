import React, {useState, useContext, useEffect } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import axios from 'axios';
import {useTranslation } from 'react-i18next'; // Text


import { useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/joy/styles';
import {   Button, Input,  Stack, FormControl, FormLabel, Typography, Box, Sheet } from '@mui/joy';
import CircularProgress from '@mui/joy/CircularProgress';
import { CssVarsProvider } from '@mui/joy/styles';
import GlobalStyles from '@mui/joy/GlobalStyles';
import { useColorScheme as useMaterialColorScheme } from '@mui/material/styles';

import CustomFormLabel from '../components/CustomFormLabel'



export default function OnBoardingPage({baseUrl}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { userEmail, setUserBusinessId, userBusinessId,userRole  } = useContext(AuthContext);
  const { t } = useTranslation(); 
  const { mode} = useMaterialColorScheme();


  const [businessName, setBusinessName] = useState('');
  const [businessShortName, setBusinessShortName] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);
  const [isLoadingBusiness, setIsLoadingBusiness] = useState(false);
  const [businessShortNameEdited, setBusinessShortNameEdited] = useState(false);
  const [errorMessages, setErrorMessages] = useState({});
  const finalErrorMessage = Object.values(errorMessages).join('\n');

  useEffect(() => {
    // If the user already has a businessId, redirect them to /admin/events
    if (userBusinessId || userRole === 'super-admin') {
      navigate('/admin/events/');
    }
  }, );

  const handleCreateBusiness = async (event) => {
    event.preventDefault(); // Prevent form submission from reloading the page

    // Create Business & attach businessId to user
    setIsLoadingBusiness(true)
    const businessData = {
      name: businessName,
      shortName: businessShortName,
      userEmail: userEmail,
    };
    let businessCreated
    try {
      businessCreated = await axios.post(`${baseUrl}/api/v1/businesses`, businessData,{ withCredentials: true });
      console.log(businessCreated.data.id)
      setUserBusinessId(businessCreated.data.id)
      navigate('/admin/events/');
    } catch (error) {
      setErrorMessages(prev => ({...prev, error:t('businessError')}));
    } finally{
      setIsLoadingBusiness(false)
    }   
  };

  const handleBusinessNameChange = (e) => {
    const newBusinessName = e.target.value;
    setBusinessName(newBusinessName);
    let formattedShortName = businessShortName;

    // Reset edited if both businessShortName & businessName are empty
    if (businessShortNameEdited && !businessShortName) {
      setBusinessShortNameEdited(false);
    }
    // Automatically generate a short name only if it has not been manually edited
    if (!businessShortNameEdited) {
      formattedShortName = newBusinessName
        .toLowerCase()
        .replace(/[\s\W-]+/g, '-')
        .replace(/^-+|-+$/g, '');
      setBusinessShortName(formattedShortName);
    }  
    checkFormValidity(newBusinessName, businessShortNameEdited ? businessShortName : formattedShortName);
  };
  
  const handleBusinessShortNameChange = (e) => {
    const newShortName = e.target.value;
    setBusinessShortName(newShortName);
    if (newShortName) {
      setBusinessShortNameEdited(true);
    } else if (!newShortName && !businessName) {
      setBusinessShortNameEdited(false);
    }
  
    const hasSpecialChars = /[^a-zA-Z0-9-]/.test(newShortName);
    setErrorMessages({
      ...errorMessages,
      businessShortName: hasSpecialChars ? t('businessShortNameSpecialCharError'): ""
    });

    checkFormValidity(businessName, newShortName);
  };
  
  const checkFormValidity = (name, shortName) => {
    const nameValid = name.trim() !== '';
    const shortNameValid = shortName.trim() !== '' && !/[^a-zA-Z0-9-]/.test(shortName);
    setIsFormValid(nameValid && shortNameValid);
  };
  

  return (
    <CssVarsProvider defaultMode="light" disableTransitionOnChange>
      <GlobalStyles
        styles={{
          ':root': {
            '--Collapsed-breakpoint': '769px', // form will stretch when viewport is below `769px`
            '--Cover-width': '50vw', // must be `vw` only
            '--Form-maxWidth': '800px',
            '--Transition-duration': '0.4s', // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={{
          width:
            'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(12px)',
          backgroundColor: 'rgba(255 255 255 / 0.2)',
        }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100dvh',
            width:
              'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
            maxWidth: '100%',
            px: 2,}}>
          <Box component="header" sx={{py: 3,display: 'flex',alignItems: 'left',justifyContent: 'space-between',}}></Box>
          <Box
            component="main"
            sx={{my: 'auto',py: 2,pb: 5,display: 'flex',flexDirection: 'column',gap: 2,width: 400,maxWidth: '100%',mx: 'auto',
              borderRadius: 'sm',}}>
            <Typography level="h4" sx={{ textAlign: 'left' }}>
            {t('welcomeBusinessOnBoarding')}
            </Typography>
    {/* Form */}
            <Box  component="form" 
                  sx={{
                    p: 2, 
                    borderRadius:'sm',border: '1px solid black', backgroundColor: theme.palette.primary.outlinedBg, borderColor: 'divider', 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    width: '100%',
                    maxWidth: 'md', }}
                  onSubmit={handleCreateBusiness}>           
              <Stack spacing={2} sx={{width:'100%'}}>
                <Typography level="h4" sx={{ textAlign: 'left' }}>
                  {t('createBusiness')}
                </Typography>
              <FormControl>
                <FormLabel htmlFor="business-name">{t('name')}</FormLabel>
                <Input
                  id="business-name"
                  value={businessName}
                  onChange={handleBusinessNameChange}
                  variant="outlined"
                  required
                />
              </FormControl>
              <FormControl>
                <CustomFormLabel htmlFor="business-shortname" label={t('businessShortName')} optional={false} flyover={true} flyoverText={'businessShortNameDescription'} />
                <Input
                  id="businessShort-name"
                  value={businessShortName}
                  onChange={handleBusinessShortNameChange}
                  variant="outlined"
                  required
                  error={!!errorMessages.businessShortName} 
                />    {/* Display the error message if it exists */}
                {errorMessages.businessShortName && (
                  <Typography color="danger">{errorMessages.businessShortName}</Typography>
                )}
              </FormControl>  
                {finalErrorMessage && (
                  <Sheet color="danger" variant="soft" sx={{ px: 2, py: 1, borderRadius: 'sm' }}>
                  {finalErrorMessage.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
                </Sheet>)}
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button 
                    type="submit" 
                    variant="outlined" 
                    disabled={!isFormValid || isLoadingBusiness} 
                    startDecorator={ isLoadingBusiness ? (
                      <CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />
                    ) : null}>
                    {t('create')}
                  </Button>
                </Stack>
                <Typography level="body2" sx={{ fontSize: '0.75rem', mb: '0.5rem' }}>
                  {t('accountCreationTermsConditionsPart1')}
                  <a href="https://orbiner.com/terms" target="_blank" rel="noopener noreferrer">
                    {t('accountCreationTermsConditionsTerms')}
                  </a>
                  {t('accountCreationTermsConditionsAnd')}
                  <a href="https://orbiner.com/privacy" target="_blank" rel="noopener noreferrer">
                    {t('accountCreationTermsConditionsPrivacy')}
                  </a>
                </Typography>           
              </Stack>
            </Box>
          </Box>
         
      {/* Footer */}
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
            <img src={`/Logo_${mode}.svg`} alt="Orbiner Logo" style={{ height: '12px', width: 'auto', alignSelf: 'start' }} /> © Orbiner {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Box>
      {/* Image */}
      <Box
        sx={{
          m:5,
          height: '45vh', 
          position: 'fixed',
          right: 0,
          top:'50%',
          transform: 'translateY(-50%)', 
          bottom: 0,
          left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: 'url(/signin_illustration.png)',
           }}
      />
    </CssVarsProvider>
  );
}
