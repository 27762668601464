import React, {useState} from 'react';
import { usePostHog } from 'posthog-js/react';
// import { useEffect, useState } from 'react';
import { Modal,  ModalDialog, DialogContent, Button, FormControl ,DialogTitle, Textarea, Stack, Typography,ModalClose} from '@mui/joy';
import { useTranslation } from 'react-i18next';

export default function Feedback({isPyro}) {
//   const [survey, setSurvey] = useState({}) 
    const [open, setOpen] = useState(true); 
    const posthog = usePostHog()
    const { t } = useTranslation(); 

    // Getting automatically the Surveys from PostHog
//   useEffect(() => {
//     posthog.getActiveMatchingSurveys((surveys) => {
//       if (surveys.length > 0) {
//         const survey = surveys[0];
//         setSurvey(survey)
//       }
//     }); 
//   }, [posthog]);

//   const handleFeedbackSubmit = (e) => {
//     e.preventDefault();
//     const feedback = e.target.elements.feedback.value;
//     posthog.capture("survey sent", {
//       $survey_id: survey.id,
//       $survey_response: feedback
//     })
//   }

  const handleFeedbackSubmit = (e) => {
    e.preventDefault();
    const feedback = e.target.elements.feedback.value;
    posthog.capture("survey sent", {
      $survey_id: '019072a8-a519-0000-c3c9-89caa564c4fb', // End of Music Survey 
      $survey_response: `Pyro: ${isPyro} : ${feedback}`
    })
    setOpen(false);
  }

  const handleClose = () => {
    setOpen(false);
};
  return (
    <div>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="survey-modal-title"
            sx={{ backdropFilter: 'blur(10px)'}}>            
            <ModalDialog variant="outlined" color="primary" sx={{ maxWidth: '440px'}} >
                <DialogTitle>{t('surveyModalTitle')}</DialogTitle>
                <DialogContent>   
                <ModalClose onClick={handleClose} />
                <Stack spacing={2}>
                  <Typography >{t('surveyModalDescription')}</Typography>  
                  <Typography level="h3">{t('endOfMusicSurveyQuestion')}</Typography>          
                    <form onSubmit={handleFeedbackSubmit}>
                        <Stack spacing={2}>
                            <FormControl>
                                <Textarea
                                    id="feedbackInput"
                                    name="feedback" 
                                    placeholder={t('surveyPlaceholder')} 
                                    required
                                    minRows={4} 
                                />
                            </FormControl>
                            <Button type="submit" variant="outlined">{t('send')}</Button>
                        </Stack>
                    </form>
                    </Stack>
                </DialogContent>
            </ModalDialog>
        </Modal>
    </div>
  );
}