import React, { useState, useEffect, useContext, useCallback } from 'react';
// import { useParams } from 'react-router-dom'; // To get dynamic Url from router dom
import axios from 'axios';
import io from 'socket.io-client';
import { useTranslation } from 'react-i18next'; 
import { AuthContext } from '../../contexts/AuthContext'; 



import EventsTable from "./EventsTable.js";
import SideBarComponent from "../../components/SideBarComponent.js"
import EventModalComponent from './EventModalComponent';
import ConfirmationModalComponent from './ConfirmationModalComponent.js';
import ShareModalComponent from './ShareModalComponent.js';


import { CssBaseline, Box, Button, Stack,  } from '@mui/joy';  




const AdminEventsPage = ({baseUrl}) => {
  const { t } = useTranslation(); 
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [selectedEvent, setSelectedEvent] = useState(null);

  const [isEditing, setIsEditing] = useState(false);
  const [eventModalOpen, setEventModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);


  const { userRole, userBusinessId } = useContext(AuthContext);

  // Get Event Information
  useEffect(() => {
    // console.log(`userRole is ${userRole} \n userBusinessId is : ${userBusinessId}`)
      const fetchAllEvents = async () => {
        // console.log(`Fetching all events ${baseUrl} \n
        // userRole : ${userRole}
        // userBusinessId : ${userBusinessId}
        // `)
        try {
          let eventsResponse;             // Get BusinessId from user authenticated
          if (userRole === 'super-admin') {
            eventsResponse = await axios.get(`${baseUrl}/api/v1/events/`, { withCredentials: true }); 
            console.log(eventsResponse)
          }else {
            eventsResponse = await axios.get(`${baseUrl}/api/v1/events/listadmin/${userBusinessId}`, { withCredentials: true }); 
            // console.log(eventsResponse)
          }
          setEvents(eventsResponse.data);            
        } catch (error) {
          console.error('Failed to fetch all events:', error);
          setError(error);
        } finally {
          setLoading(false);
        }
      };
      fetchAllEvents();


    // Establish WebSocket connection
    // Determine the WebSocket URL based on the environment
    const wsBaseUrl = baseUrl.replace(/^http/,'ws'); 
    // console.log(`Websocket url : ${wsBaseUrl}`)
    const socket = io(wsBaseUrl, { path: '/socket.io' });

    // Handle new event creation
    socket.on('event-created', (newEvent) => {
      // console.log('New event created:', newEvent);
      if (userRole !== 'super-admin' && newEvent.businessId !== userBusinessId) {
        return; // Ignore events not related to the user's business
      }
      // Update the local state to include the new event
      setEvents((currentEvents) => [...currentEvents, newEvent]);
    });

    socket.on('event-updated', (updatedEvent) => {
      // console.log('Event updated:', updatedEvent);
      if (userRole !== 'super-admin' && updatedEvent.businessId !== userBusinessId) {
        return; // Ignore events not related to the user's business
      }
      
      setEvents((currentEvents) => currentEvents.map(event => {
        if (event.id === updatedEvent.id) {
          return updatedEvent; // Replace the existing event with the updated one
        } else {
          return event; // Return the event unchanged
        }
      }));
    });

    return () => socket.disconnect();

  }, [baseUrl,userRole,userBusinessId]);


  // Handle Edit / Delete
  const handleEditEvent = (selectedEventId) => {
    setIsEditing(true)
    setEventModalOpen(true); 
    const eventToEdit = events.find(event => event.id === selectedEventId);
    setSelectedEvent(eventToEdit); 
  };

  const handleDeleteEvent = (selectedEventId) => {
    const eventToDelete = events.find(event => event.id === selectedEventId);
    setSelectedEvent(eventToDelete);
    setConfirmationModalOpen(true);
  };

 
  const handleEventPayment = async (selectedEvent) => {
    try{
      // console.log(`${baseUrl}/api/v1/payment/checkout/${selectedEvent}`)
      const response = await axios.post(`${baseUrl}/api/v1/payment/checkout/${selectedEvent}`, {}, { withCredentials: true });
      // console.log(response.data.url)
      // const body = await response.json()
      // console.log(body.url)
      window.location.href = response.data.url
    } catch(error) 
    {
      console.error('Failed to initiate payment:', error);
    };
  };

  const confirmDeleteEvent = async () => {
    if (selectedEvent) {
      try {
        // Placeholder for actual Axios delete call
        await axios.delete(`${baseUrl}/api/v1/events/${selectedEvent.id}`, {withCredentials: true});
          setEvents(events.filter(event => event.id !== selectedEvent.id));
        setConfirmationModalOpen(false); // Close the modal
      } catch (error) {
        console.error('Error deleting event:', error);
      }
    }
  };

  const handleShareEvent = (selectedEventId) => {
    const eventToShare = events.find(event => event.id === selectedEventId);
    setSelectedEvent(eventToShare);
    setShareModalOpen(true);
  };

  return (
    <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <SideBarComponent baseUrl={baseUrl} settings={false}/> 
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        padding: '1em'
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '1em' }}>
        <h2>{t('events')}</h2>
        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
          <Button onClick={() => {
              setIsEditing(false)
              setEventModalOpen(true); 
            }} 
          variant="soft" color="primary">{t('createEvent')}</Button>
        </Stack>
      </Box>
      <Box className="admin-table">
        {events.length > 0 ? (
          <EventsTable 
            events={events} 
            onEditEvent={handleEditEvent}
            onDeleteEvent={handleDeleteEvent} 
            onShareEvent ={handleShareEvent}
            onPayEvent = {handleEventPayment}
          />
        ) : (
          <div>{t('noEvents')}</div>
        )}
      </Box>
      <EventModalComponent 
        open={eventModalOpen} 
        handleClose={() => setEventModalOpen(false)} 
        baseUrl={baseUrl}
        isEditing={isEditing}
        eventData={selectedEvent}         
      />
      <ConfirmationModalComponent 
        open={confirmationModalOpen}
        handleClose={() => setConfirmationModalOpen(false)}
        handleDelete={confirmDeleteEvent}
        deleteItem={selectedEvent?.name}
      />
      {selectedEvent && <ShareModalComponent 
        baseUrl={baseUrl}
        open={shareModalOpen}
        handleClose={() => setShareModalOpen(false)}
        eventData = {selectedEvent} 
        userBusinessId={userBusinessId}        
      />}
    </Box>
  </Box>
  );
};

export default AdminEventsPage;