import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './i18n';


import { PostHogProvider} from 'posthog-js/react'

import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
} from '@mui/material/styles';

import { CssVarsProvider as JoyCssVarsProvider, extendTheme } from '@mui/joy/styles';
import CssBaseline from '@mui/joy/CssBaseline';


import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
  const options = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
    opt_in_site_apps: true
  }

const materialTheme = materialExtendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main:  "#280C82",
        },
        background: {
          default: "#F4F2FF",
          paper: "#ffffff",
        },
        text: {
          primary:"#000000",
        },
      },
    components: {
      // Customizing DateTimePicker and its internal components
      MuiTextField: { 
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              backgroundColor: "#ffffff",
              '& fieldset': { 
                borderColor: "#000000", 
              },
              '&.Mui-focused fieldset': { 
                borderColor: '#280C82',
              },
            },
          },
        },
      },
      // For date TimePicker : NOT WORKING
      // MuiOutlinedInput: {
      //   styleOverrides: {
      //     input: {
      //       padding: '7px 16px', // Custom vertical and horizontal padding
      //       '&.MuiReadOnly': { // Targeting read-only inputs specifically, if needed
      //         padding: '7px 16px', // Apply the same or different padding
      //       },
      //     },
      //   },
      // },
      MuiButton: { 
        styleOverrides: {
          root: {
            color: 'black', 
            '&:hover': {
              backgroundColor: "#B4ADE0", 
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            border:  '1px solid black ', // Specify border width and style
            borderRadius: '1em',
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          day: {
            '&.Mui-selected': {
              backgroundColor: "#280C82",
            },},},},
    },
  },
  dark: {
    palette: {
      primary: {
        main:  "#5C40B4",
      },
      background: {
        default: "#150547",
        paper: "#000000",
      },
      text: {
        primary:"#ffffff",
      },
    },
  components: {
    // Customizing DateTimePicker and its internal components
    MuiTextField: { 
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            backgroundColor: "#150547",
            '& fieldset': { 
              borderColor: "#ffffff", 
            },
            '&.Mui-focused fieldset': { 
              borderColor: '#5C40B4',
            },
          },
        },
      },
    },
    // For date TimePicker : NOT WORKING
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     input: {
    //       padding: '7px 16px', // Custom vertical and horizontal padding
    //       '&.MuiReadOnly': { // Targeting read-only inputs specifically, if needed
    //         padding: '7px 16px', // Apply the same or different padding
    //       },
    //     },
    //   },
    // },
    MuiButton: { 
      styleOverrides: {
        root: {
          color: 'white', 
          '&:hover': {
            backgroundColor: "#1F0869", 
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          border:  '1px solid white ', // Specify border width and style
          borderRadius: '1em',
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        day: {
          '&.Mui-selected': {
            backgroundColor: "#5C40B4",
          },},},},
  },
},


},
    // Continue for other variants
});

const joyTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {       
          "50": "#F4F2FF",
          "100": "#CDC5FC",
          "200": "#B4ADE0",
          "300": "#B4ADE0",
          "400": "#5C40B4",
          "500": "#280C82",
          "600": "#1F0869",
          "700": "#150547",
          "800": "#150547",
          "900": "#150547",
          "outlinedBorder": "#000000",
          "outlinedColor": "#000000",
          "outlinedHoverBg": "#CDC5FC",
          "outlinedActiveBg": "#B4ADE0",
          "outlinedDisabledBg": "#ffffff",
          "outlinedDisabledBorder": "var(--joy-palette-neutral-600)",
          "outlinedBg": "#ffffff",
          "softBorder": "#000000",
          "softBg": "#E6E2FF",
          "softColor": "#000000",
          "softHoverBg": "#CDC5FC",
          "softActiveBg": "#B4ADE0",
          "softActiveColor": "#000000",
          "softDisabledBorder": "var(--joy-palette-neutral-600)",
          "solidDisabledBg": "var(--joy-palette-primary-50)",
          "solidDisabledColor": "var(--joy-palette-primary-300)",
          "accentColor":"#FF294D"
        },
        neutral: {
          "50": "#ffffff",
          "100": "#CDC5FC",
          "200": "#B4ADE0",
          "300": "#B4ADE0",
          "500": "#000000",
          "600": "#000000",
          "outlinedColor": "#000000",
          "outlinedDisabledBorder": "var(--joy-palette-neutral-600)",
          "outlinedActiveBg": "#B4ADE0",
          "outlinedBorder": "#000000",
          "outlinedHoverBg": "#CDC5FC",
          "plainActiveBg":"var(--joy-palette-neutral-100)"
        },
        warning: {
          "50": "#E0E0AD",
          "100": "#D8D898",
          "200": "#CACA81",
          "300": "#BBBB6C",
          "400": "#ADAD5B",
          "500": "#686820",
          "600": "#535313",
          "700": "#3E3E0B",
          "800": "#2B2B03",
          "900": "#1F1F01"
        },
        text: {
          "primary": "#000000",
          "white": "#ffffff"
        },
        divider: "rgba(0,0,0,100)",
        background: {
          body: "#F4F2FF",
          surface: "#E6E2FF",
          color: "#000000",
          // popup: "#F4F2FF",
          // level1: "#E6E2FF",
          // level2: "#CDC5FC",
          // tooltip: "#000000",
          // backdrop: "#000000",
          // level3: "#000000"
        }
      },
      divider: {
        "lineColor": "#000000"
      },
    },
    dark: {
      palette: {
        primary: {
          "50": "#E6E2FF",
          "100": "#CDC5FC",
          "200": "#B4ADE0",
          "300": "#8E82D5",
          "400": "#5C40B4",
          "500": "#4729A9",
          "600": "#5C40B4",
          "700": "#280C82",
          "800": "#1F0869",
          "900": "#150547",
          "outlinedBorder": "#ffffff",
          "outlinedColor": "var(--joy-palette-primary-50)",
          "outlinedHoverBg": "var(--joy-palette-primary-600)",
          "outlinedActiveBg": "var(--joy-palette-primary-500)",
          "outlinedDisabledBorder": "var(--joy-palette-primary-300)",
          "outlinedDisabledColor": "var(--joy-palette-primary-300)",
          "outlinedBg": "var(--joy-palette-primary-900)",
          "softBorder": "#5C40B4",
          "softBg": "#4729A9",
          "softColor": "var(--joy-palette-primary-50)",
          "softHoverBg": "#8E82D5",
          "softActiveBg": "#5C40B4",
          "softActiveColor": "#000000",
          "softDisabledBorder": "var(--joy-palette-neutral-600)",
          "solidDisabledBg": "var(--joy-palette-primary-800)",
          "solidDisabledColor": "var(--joy-palette-primary-700)",
          "accentColor":"#FF294D"
        },
        neutral: {
          "50": "#000000",
          "100": "#150547",
          "200": "#1F0869",
          "300": "#1F0869",
          "500": "#ffffff",
          "600": "#ffffff",
          "700": "#ffffff",
          "outlinedColor": "#ffffff",
          "outlinedDisabledBorder": "var(--joy-palette-neutral-600)",
          "outlinedActiveBg": "#1F0869",
          "outlinedBorder": "#ffffff",
          "outlinedHoverBg": "#150547",
          "plainActiveBg":"var(--joy-palette-neutral-100)",
          "plainHoverBg":"var(--joy-palette-neutral-700)",
          "plainColor":"#ffffff",
        },
        warning:{
          "50": "#E0E0AD",
          "100": "#D8D898",
          "200": "#CACA81",
          "300": "#BBBB6C",
          "400": "#ADAD5B",
          "500": "#686820",
          "600": "#535313",
          "700": "#3E3E0B",
          "800": "#2B2B03",
          "900": "#1F1F01"
        },
        divider: "rgba(255,255,255,100)",
        background: {
          body: "var(--joy-palette-primary-800)",
          surface: "var(--joy-palette-primary-700)",
          color:"#150547",
          level1: "var(--joy-palette-primary-700)",
          level2: "var(--joy-palette-primary-700)",
          level3: "var(--joy-palette-primary-600)",
          // popup: "var(--joy-palette-primary-500)",
          // tooltip: "var(--joy-palette-primary-600)"
        },
        text: {
          "primary": "#FFFFFF",
          "secondary": "var(--joy-palette-primary-50)",
          "tertiary": "var(--joy-palette-primary-200)",
          "icon": "var(--joy-palette-primary-50)"
        }
      }
    }
  },
  shape: {
    borderRadius:1,
  },
  fontFamily: {
    display: 'Poppins', 
    body: 'Roboto', 
  },
  
  });

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/serviceworker.js')
      .then(registration => {
        console.log('Service Worker registered with scope:', registration.scope);
      }).catch(error => {
        console.error('Service Worker registration failed:', error);
      });
  }


root.render(
  <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
    <JoyCssVarsProvider theme={joyTheme} >
      <CssBaseline enableColorScheme />
      <PostHogProvider
        apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
        options={options}
      >
        {/* <React.StrictMode> */}
        <App />
        {/* </React.StrictMode> */}
      </PostHogProvider>
    </JoyCssVarsProvider>
  </MaterialCssVarsProvider>

);
  
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();