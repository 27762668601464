// ProtectedRoute.js
import React, { useContext } from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import LinearProgress from '@mui/joy/LinearProgress';

export const ProtectedRoute = ({ children, requiredRole }) => {
  const { isAuthenticated, userRole, isLoading } = useContext(AuthContext);
  const location = useLocation();

  // if (isAuthenticated === null) {
  //   return <CircularProgress variant="soft" />  ;
  // }

  if (isLoading) {
    return <LinearProgress color="neutral" variant="outlined" />    ;
  }

  
  if (!isAuthenticated || (requiredRole && userRole && userRole !== requiredRole && userRole !== 'super-admin')) {
    // console.log(`isAuthenticated is ${isAuthenticated} \n
    // userRole is ${userRole}
    // requiredRole is ${requiredRole}   
    // User is not authenticated or has not the sufficient rights
    // `)

    // console.log('User is not authenticated or has not the sufficient rights')
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }
  
  return children;
};
