import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext'; // Adjust the path accordingly
import { useNavigate  } from 'react-router-dom';

import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Text

import SideBarComponent from "../components/SideBarComponent.js"
import CustomFormLabel from '../components/CustomFormLabel.js';
import QRCodeComponent from '../components/QRCodeComponent.js'
import { cleanFilename } from '../utils/fileUtils';


import { useTheme } from '@mui/joy';
import { useColorScheme as useJoyColorScheme } from '@mui/joy/styles';
import { useColorScheme as useMaterialColorScheme } from '@mui/material/styles';

import { CssBaseline, Box, Button, IconButton, Stack, FormControl, Divider, Input, Snackbar, Typography, CircularProgress } from '@mui/joy';


const AdminSettingsPage = ({ baseUrl }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const frontendUrl = process.env.REACT_APP_FRONTEND_URL
    const { mode, setMode: setMaterialMode } = useMaterialColorScheme();
    const { setMode: setJoyMode } = useJoyColorScheme();

    
    const navigate = useNavigate();



    const { userBusinessId, userBusinessName } = useContext(AuthContext);
    const [businessShortName, setBusinessShortName] = useState(null);
    const [businessStripeCustomerId, setBusinessStripeCustomerId] = useState(null);
    const [businessLogo, setBusinessLogo] = useState(null); 

    const [errorMessage, setErrorMessage] = useState();

    // Logo
    const [isLoadingUploadLogo, setIsLoadingUploadLogo]=useState(false)
    const [isLogoLoaded, setisLogoLoaded]= useState(false);
    const [logoFile, setLogoFile] = useState(null);
    // Form itself
    const [isChanged, setIsChanged]= useState(false);

    const [successSnackBarOpen, setSuccessSnackBarOpen] = useState(false);


    // Get Settings
    useEffect(() => {
        const fetchBusinessInfo = async () => {
            try {
                const businessResponse = await axios.get(`${baseUrl}/api/v1/businesses/admin/${userBusinessId}`, { withCredentials: true });
                console.log(businessResponse)
                setBusinessShortName(businessResponse.data.shortName)
                setBusinessStripeCustomerId(businessResponse.data.stripeCustomerId)
                if (businessResponse.data.logoPath){
                    // if logo exist
                    setBusinessLogo(businessResponse.data.logoPath);
                }
            } catch (error) {
                console.error('Failed to fetch business info:', error);
            }
        };
        fetchBusinessInfo();
    }, [baseUrl, userBusinessId]);

    const handleCopyText = (type) => {
        navigator.clipboard.writeText(`${frontendUrl}/events/${type}`)
        setSuccessSnackBarOpen(true);
    };



    const handleStripeBilling = async () => {
        const response = await axios.get(`${baseUrl}/api/v1/businesses/admin/${userBusinessId}`, { withCredentials: true });
        // console.log&(response)
        if (response.data.stripeCustomerId) {
            // Open Customer Portal
            const response = await axios.post(`${baseUrl}/api/v1/payment/customerPortal/`, {}, { withCredentials: true });
            window.location.href = response.data.url;
        } else {
            // TODO : add a new page for settings including Stripe.
            // Disable stripe settings if no customerId found with a message
            console.log('No StripeCustomerId found')
        }
    }

    const uploadFile = async (file) => {    
    const formData = new FormData();
    const mimeType = file.type;  // Get the MIME type of the original file
    formData.append("file", file);
    formData.append("index", 0);
    formData.append("total", 1);
    formData.append('mimeType', mimeType);
    formData.append('originalName', file.name);
    // console.log(file);
    try {        

        await axios.post(`${baseUrl}/api/v1/file/upload/${userBusinessId}?mimeType=${mimeType}&index=${0}&originalName=${file.name}`, formData, {
        headers: {'Content-Type': 'multipart/form-data',}, withCredentials: true,});
        return true;  // Success
    } catch (error) {
        console.log(error)
        if (error.response.data.error === 'UPLOAD_INVALID_FILE'){
            console.log()
        // Invalid file type
        setErrorMessage(t("logoFormatError"));}    
    } finally {
        setIsLoadingUploadLogo(false)
        setIsChanged(true)
    }
} 
    // Update business Information
    const handleSettingsUpdate = async () => {
        try {

            setIsLoadingUploadLogo(true)
            await uploadFile(logoFile);
            setIsLoadingUploadLogo(false)

            const businessData = {logoPath: logoFile.name};
            const response = await axios.patch(`${baseUrl}/api/v1/businesses/${userBusinessId}`, businessData, { withCredentials: true }); 
            console.log(response)
            setBusinessLogo(response.data.logoPath);         
            setisLogoLoaded(false)   
            setIsChanged(false)   
            navigate('/admin/events')
        }
        catch (error) {
            console.log(error)
        }
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <SideBarComponent baseUrl={baseUrl} settings={true} />
            <Box
                component="main"
                sx={{
                    display: 'flex',
                    flexGrow: 1,
                    flexDirection: 'column',
                    padding: '1em',
                    justifyContent: 'space-between'
                }}>
                <Box>                    
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '1em' }}>
                        <h2>{t('settings')}</h2>
                    </Box>
                    <form onSubmit={handleSettingsUpdate}>
                    <Stack
                        className="settings-main"
                        justifyContent="left"
                        alignItems="left"
                        spacing={2}>
                        <Button variant="outlined"
                            sx={{maxWidth:"180px"}}
                            onClick={() => {
                                setMaterialMode(mode === 'dark' ? 'light' : 'dark');
                                setJoyMode(mode === 'dark' ? 'light' : 'dark');
                            }}
                            >
                            <span className="material-symbols-outlined">
                                {mode === 'dark' ? 'light_mode' : 'dark_mode'}
                            </span>
                            {mode === 'dark' ? t('actionTurnLightmode') : t('actionTurnDarkmode')}
                        </Button>
                        <h3>{t('information')}</h3>
                        <Box>
                            <CustomFormLabel htmlFor="business-name" label={t('name')} optional={false} flyover={false} />
                            <Input placeholder={userBusinessName} sx={{ width: '250px' }} disabled />
                        </Box>
                        <Box>
                            <CustomFormLabel htmlFor="business-shortname" label={t('businessShortName')} optional={false} flyover={true} flyoverText={'businessShortNameDescription'} />
                            <Input placeholder={businessShortName} sx={{ width: '250px' }} disabled />
                        </Box>                        
                        <FormControl>
                            <CustomFormLabel htmlFor="business-logo" label={t('businessLogo')} optional={false} flyover={true} flyoverText={'businessShortNameDescription'} />
                            <Box sx={{display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center'}}>
                               <Button component="label" variant="soft" color="primary" sx={{                                         
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        width: 64,
                                        height: 64,
                                        border: '1px solid',
                                        borderColor: 'divider',
                                        borderRadius: 'md',
                                        backgroundColor: theme.palette.primary.outlinedBg,
                                        mr: 2,}}>                                  
                                    {                                    
                                    (businessLogo && !isLoadingUploadLogo && !isLogoLoaded) ? (<img src={businessLogo} alt="Logo" style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>) 
                                    : isLoadingUploadLogo ? (<CircularProgress size="sm" sx={{ bgcolor: 'background.surface' }} />) 
                                    : isLogoLoaded ? (<span className="material-symbols-outlined">download_done</span>) 
                                     : (<span className="material-symbols-outlined">upload_file</span>)}              
                                    <input type="file" hidden onChange={(e) => {setLogoFile(cleanFilename(e.target.files[0])); setisLogoLoaded(true); setIsChanged(true)}} />
                                </Button>                  
                                <Typography>{t('uploadLogo')}</Typography>
                            </Box>
                            { errorMessage && !isLogoLoaded && (<Typography color="danger">{errorMessage}</Typography>)}
                            </FormControl>
                        <Box>
                            <CustomFormLabel htmlFor="business-billing" label={t('billingTitle')} optional={false} flyover={false} />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    cursor: businessStripeCustomerId ? 'pointer' : 'not-allowed',
                                    opacity: businessStripeCustomerId ? 1 : 0.5,
                                }}
                                onClick={businessStripeCustomerId ? handleStripeBilling : null}>
                                <Typography>{t('billingDescription')}</Typography>
                                <span className="material-symbols-outlined" style={{ fontSize: '14px', paddingLeft: 3 }}>open_in_new</span>
                            </Box>

                            {!businessStripeCustomerId && (
                                <>
                                    <Typography color="warning" level="body-xs">{t('billingWarning')}</Typography>
                                </>
                            )}
                        </Box>
                        
                        {/* Share Business Event list */}
                        <h3>{t('share')}</h3>
                        <CustomFormLabel htmlFor="business-link" label={t('businessLinkTitle')} optional={false} flyover={false} />
                        <Box sx={{ display: 'flex', justifyContent: 'left' }}>
                            <QRCodeComponent link={`${frontendUrl}/business/${businessShortName}`} imageName={`${businessShortName}`} />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                            <Box sx={{
                                display: 'flex', 
                                alignItems: 'center', 
                                bgcolor: mode ==='dark' ? theme.palette.primary[600] : theme.palette.primary[50],  
                                borderRadius: 'md', 
                                border: '1px solid',
                                borderColor: 'divider', 
                                width: 'fit-content'
                            }}>
                                <IconButton aria-label="copy" onClick={() => handleCopyText(businessShortName)} sx={{ width: 12, height: 12 }}>
                                    <span className="material-symbols-outlined" style={{ fontSize: '14px' }}>content_copy</span>
                                </IconButton>
                                <Divider orientation="vertical" sx={{ bgcolor: 'black' }} />
                                <Typography sx={{ mx: 1, textAlign: 'center', fontSize: '14px' }}>
                                    {`${frontendUrl}/business/${businessShortName}`}
                                </Typography>
                            </Box>
                            <IconButton
                                onClick={() => window.open(`${frontendUrl}/business/${businessShortName}`, '_blank')}
                                variant='outlined' className="action-icons">
                                <span className="material-symbols-outlined">open_in_new</span>
                            </IconButton>
                        </Box>
                    </Stack>
                    </form>

                </Box>
                
                <Snackbar
                    autoHideDuration={800}
                    open={successSnackBarOpen}
                    variant='outlined'
                    color='primary'
                    size="sm"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    onClose={() => setSuccessSnackBarOpen(false)}
                >
                    {t('linkCopiedSnackbar')}
                </Snackbar>
                {/* Buttons at the bottom right */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1, gap: 1 }}>
                    <Button
                        variant="soft"
                        disabled={!isChanged} 
                        onClick={handleSettingsUpdate}>
                        {t('save')}</Button>
                </Box>

            </Box >
        </Box >
    );
};

export default AdminSettingsPage;