import React from 'react';
import { Box, Typography } from '@mui/joy';
import Card from '@mui/joy/Card';

import { useTheme } from '@mui/joy';


const EventCardStack = ({ events }) => {
    const frontendUrl = process.env.REACT_APP_FRONTEND_URL
    const theme = useTheme();

    return (
        <Box sx={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: 2,
            mt: 2,
            justifyContent: 'flex-start',
        }}>
            {events.map((event) => (
                <Card
                    variant="outlined"
                    color='primary'
                    key={event.id}
                    component="a"
                    href={`${frontendUrl}/events/${event.publicLink}`}
                    target="_blank"
                    sx={{
                        minWidth: 320,
                        maxWidth: 320,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        textDecoration: 'none',
                        ':hover': {
                            boxShadow: 'md',
                            backgroundColor: theme.palette.primary[100],
                        },
                        '@media (max-width: 688px)': {
                            minWidth: '100%',
                            maxWidth: '100%'
                        }
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box component="img"
                                src={event.imgPath}
                                alt={event.name}
                                sx={{
                                    width: 64,
                                    height: 64,
                                    borderRadius: 'md',
                                    border: 1,
                                    borderColor: 'divider',
                                    mr: 2
                                }} />
                            <Box>
                                <Typography level="h3" fontWeight="bold">
                                    {event.name === event.location ? event.location : event.name}
                                </Typography>
                                <Typography>
                                    {new Date(event.scheduledTime).toLocaleDateString('fr-FR')}
                                    {event.name !== event.location && ` - ${event.location}`}
                                </Typography>
                            </Box>
                        </Box>
                        <span className="material-symbols-outlined">
                            open_in_new
                        </span>
                    </Box>
                </Card>
            ))}
        </Box>
    );
};

export default EventCardStack;
