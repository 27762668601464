import React, { useEffect, useState } from 'react';

import { Drawer, Stack, Typography, Box, Button } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import TimePicker from '../../components/TimePicker';

const ChangeScheduledTimeDrawerComponent = ({
  isDrawerOpen,
  toggleDrawer,
  updateEvent,
  scheduledTime,
  ntpOffset,
}) => {
  const { t } = useTranslation();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    // Initialize Selected Date in timepicker
    if (scheduledTime && !selectedDate) {
      setSelectedDate(new Date(scheduledTime));
    }
  }, [scheduledTime]);

  const handleSubmit = () => {
    if (!isNaN(selectedDate)) {
      updateEvent(selectedDate);
    } else {
      console.error('Invalid date selected');
    }
  };

  // Button set the time to ntpTime + 15 seconds
  const handleTimewButton = delay => {
    const newScheduledTime = new Date(
      Math.round(new Date(Date.now() + ntpOffset).getTime() / 1000) * 1000 +
        delay * 1000
    );
    setSelectedDate(newScheduledTime);
    // updateEvent(newScheduledTime);
    setIsChanged(true);
  };

  const handleTimewPicker = newTime => {
    console.log(`Picker : ${newTime}`);
    console.log('Set Scheduled Time with Picker');
    setSelectedDate(newTime); // Update local state
    setIsChanged(newTime !== new Date(scheduledTime).getTime());
  };

  return (
    <Drawer
      anchor="bottom"
      open={isDrawerOpen}
      onClose={toggleDrawer(true)}
      variant="outlined"
      color="primary"
    >
      <Stack
        direction="column"
        alignItems="left"
        spacing={1}
        mx="auto"
        mb={2}
        p={2}
        sx={{ maxWidth: '1000px' }}
      >
        <Typography level="h4">{t('scheduleMusic')}</Typography>
        <Box sx={{ flexGrow: 1 }}>
          <TimePicker onTimeSet={handleTimewPicker} />
        </Box>
        <Typography level="h3">{t('or')}</Typography>
        <Typography level="h4">{t('launchMusicImmediatly')}</Typography>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            variant="soft"
            onClick={() => {
              handleTimewButton(30); /* toggleDrawer(false)();*/
            }}
          >
            30 sec
          </Button>
          <Button
            variant="soft"
            onClick={() => {
              handleTimewButton(300); /*toggleDrawer(false)();*/
            }}
          >
            5 min
          </Button>
        </Box>
        {/* Drawer Buttons */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'right',
            width: '100%',
            maxWidth: '640px',
          }}
        >
          <Button
            variant="plain"
            color="neutral"
            onClick={() => {
              setSelectedDate(scheduledTime);
              setIsChanged(false);
              toggleDrawer(false)();
            }}
          >
            {t('cancel')}
          </Button>
          <Button
            variant="outlined"
            disabled={!isChanged}
            onClick={() => {
              handleSubmit();
              toggleDrawer(false)();
            }}
          >
            {t('changeScheduledDate')}
          </Button>
        </Box>
      </Stack>
    </Drawer>
  );
};

export default ChangeScheduledTimeDrawerComponent;
