import React, {useState, useEffect, useRef} from 'react';
import Box from '@mui/joy/Box';
import IconButton from '@mui/joy/IconButton';
import Typography from '@mui/joy/Typography';
import CircularProgress from '@mui/joy/CircularProgress';
import { useTheme } from '@mui/joy/styles';
import { useTranslation } from 'react-i18next';
import {formatDate, formatHour} from '../utils/dateFormat'
import Feedback from './SurveyComponent';


const MusicPlayerComponent = ({ isEventPassed, isSynchronized, isNetworkError, isMusicPlaying, isMusicFinished,isMuted,isMusicError,scheduledTime, toggleMute}) => {
  const { t } = useTranslation(); 
  // PlaybackDelta is the playback delta in seconds compared to the audioContext current time (that can be different than 0)
  // deltaScheduledWServer is the difference between the scheduled time (when the music needs to start) and the current time (synchronized w server)
  // const [audioBuffer, setAudioBuffer] = useState(null);
  // const [isNetworkError, setIsNetworkError] = useState(false)
  const theme = useTheme();
  // Format music player message depending on the delta Scheduled Time with server (provided by useNTPTime custom hook)
  const [musicListened, setMusicListened] = useState(false)

  let musicMessage = '';
  let musicPlayerBgColor = theme.palette.background.surface;
  let iconType = '';
  let iconColor = theme.palette.text.primary;


  // Making sure that the user has played at least 60 secondes of the music to show survey 
  const musicStartTimeRef = useRef(null);

  useEffect(() => {
    if (isMusicPlaying) {
      if (!musicStartTimeRef.current) {
        musicStartTimeRef.current = Date.now();
      }

      const interval = setInterval(() => {
        const elapsedTime = (Date.now() - musicStartTimeRef.current) / 1000;
        if (elapsedTime >= 60) {
          setMusicListened(true);
          clearInterval(interval);
        }
      }, 1000);

      return () => clearInterval(interval);
    } else {
      musicStartTimeRef.current = null;
    }
  }, [isMusicPlaying]);


  if(isEventPassed){
    return
  }
  
  // Determine state and corresponding UI elements
  if (!isSynchronized) {
    // Before Synchronization
    musicMessage = t('synchronizationMessage');
    iconType = <CircularProgress />;
  } else if (isNetworkError) {
    // If synchronization did not work because of error network
    musicMessage = t('networkErrorMessage');
    iconType = <span className="material-symbols-outlined">signal_cellular_connected_no_internet_0_bar</span>;
    musicPlayerBgColor = theme.palette.danger[200];
  } else if (isMusicError) {
    musicMessage = t('musicLoadingError');
    iconType = <span className="material-symbols-outlined">media_output_off</span>;
    musicPlayerBgColor = theme.palette.danger[200];
  } else if (isMusicFinished) {   
    musicMessage = t('eventFinishedMessage');
    iconType = <span className="material-symbols-outlined">event_busy</span>;
  } else if (isMusicPlaying && !isMuted) {
    musicMessage = t('musicInProgressMessage');
    iconColor= theme.palette.text.white;
    iconType = <IconButton 
      sx={{
        backgroundColor: theme.palette.primary.softActiveBg,
        color: iconColor,
        borderRadius: '50%',
        border: `1px solid`,
        borderColor: 'divider',
      }} 
      onClick={toggleMute}>
                 <span className="material-symbols-outlined">volume_up</span>
               </IconButton>;
    musicPlayerBgColor = theme.palette.primary[500];      
   } else if (isMusicPlaying && isMuted) {
    musicMessage = t('musicInProgressMutedMessage');
    iconColor= theme.palette.text.white;
    iconType = <IconButton 
    sx={{
        backgroundColor: theme.palette.primary[600], 
        color: iconColor,
        borderRadius: '50%',
        border: '1px solid',
        borderColor: 'divider',
      }} 
      onClick={toggleMute}>
                 <span className="material-symbols-outlined">volume_off</span>
               </IconButton>;
    musicPlayerBgColor = theme.vars.palette.primary.accentColor;
  } else if (!isMusicPlaying && !isMusicFinished) {
    // console.log(scheduledTime)
    const now = new Date();
    const isToday = scheduledTime.getDate() === now.getDate() &&
                    scheduledTime.getMonth() === now.getMonth() &&
                    scheduledTime.getFullYear() === now.getFullYear();
    if (isToday) {
      musicMessage = t('beforeMusicStartMessage',{scheduledHour:formatHour(scheduledTime)});
    }else {
      musicMessage = t('beforeLongMusicStartMessage',{scheduledTime:formatDate(scheduledTime)});
    }
    iconType = <span className="material-symbols-outlined">schedule</span>;
  }
  
  
  
  
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1em',
      borderRadius: 'md',
      backgroundColor: musicPlayerBgColor,
      border: `1px solid`,
      borderColor: 'divider',
    }}>
      {isMusicFinished && musicListened && (
        <>
        <Feedback></Feedback>
        </>
      )}
      {iconType}
      <Typography sx={{ marginLeft: '1em', color: iconColor, flexGrow: 1, textAlign:'center' }}>{musicMessage}</Typography>
      <Box sx={{ width: '40px', height: '40px' }} /> 
    </Box>
  );
};

export default MusicPlayerComponent;
