import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next'; // Text

import SideBarComponent from "../components/SideBarComponent.js"
// import ConfirmationModalComponent from '../components/ConfirmationModalComponent.js';



import { CssBaseline, Box, Button, Stack,FormLabel,Input } from '@mui/joy';  // Use Joy UI components


const GlobalSettingsPage = ({baseUrl}) => {
  const { t } = useTranslation(); 
  const navigate = useNavigate();



  const [settings, setSettings] = useState([]);
  const [minimumFee, setMinimumFee]= useState([]);
  const [feePerHabitant, setFeePerHabitant]= useState([]);
    // Get Event information from API & construct Music path
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Get Settings
  useEffect(() => {
      const fetchAllSettings = async () => {
        try {
          setLoading(true);
          const settingsResponse = await axios.get(`${baseUrl}/api/v1/settings/`, { withCredentials: true }); 
          // console.log(settingsResponse)
          setSettings(settingsResponse.data);            
        } catch (error) {
          console.error('Failed to fetch all settings:', error);
          setError(error);
        } finally {
          setLoading(false);
        }
      };
      fetchAllSettings();
  }, [baseUrl]);


  const handleSettingsUpdate = async () => {
    navigate('/admin/events/');
  }

  return (
    <Box sx={{ display: 'flex' }}>
    <CssBaseline />
    <SideBarComponent baseUrl={baseUrl} settings={false}/> 
    <Box
      component="main"
      sx={{
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'column',
        padding: '1em',
        justifyContent: 'space-between' 
      }}
    >
      <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: '1em' }}>
        <h2>{t('settings')}</h2>
      </Box>
      <Stack
        className="settings-main"
        justifyContent="left"
        alignItems="left"
        spacing={2}
      >
        <Box>
          <FormLabel>{t('minimumFee')}</FormLabel>
          <Input
            placeholder={t('minimumFee')}
            value={settings.minimumFee}
            onChange={(e) => setMinimumFee(e.target.value)}
            sx={{ maxWidth: '120px' }} // Set maximum width
          />
          </Box>
          <Box>
          <FormLabel>{t('feePerHabitant')}</FormLabel>
          <Input
            placeholder={t('feePerHabitant')}
            value={settings.feePerHabitant}
            onChange={(e) => setFeePerHabitant(e.target.value)}
            sx={{ maxWidth: '120px' }} // Set maximum width
          />
          </Box>
      </Stack>
      </Box>
      {/* Buttons at the bottom right */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1, gap: 1 }}>
        <Button variant="outlined" onClick={() => {navigate('/admin/events')}}>{t('cancel')}</Button>
        <Button 
          variant="soft"
          onClick={handleSettingsUpdate}>
            {t('save')}</Button>
      </Box>

      {/* <ConfirmationModalComponent 
        open={confirmationModalOpen}
        handleClose={() => setConfirmationModalOpen(false)}
        handleDelete={confirmDeleteEvent}
        deleteItem={selectedEvent?.name}
      /> */}
 
    </Box>
  </Box>
  );
};

export default GlobalSettingsPage;