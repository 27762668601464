// components/WelcomeModalComponent.js
import React from 'react';
import { Modal,  ModalDialog, DialogContent, Button, Stack,DialogTitle, Sheet } from '@mui/joy';
import Box from '@mui/joy/Box';
import { useTranslation } from 'react-i18next';


const ConfirmationModalComponent = ({open,handleClose, handleDelete, deleteItem }) => {
  const { t } = useTranslation(); 
  return (
    <Modal 
        open={open} 
        onClose={() => {}} 
        aria-labelledby="welcome-modal-title"
        sx={{ backdropFilter: 'blur(10px)' }}    
    >
        <ModalDialog 
        variant="outlined"
        color="primary"
        sx={{ maxWidth: '450px'}}
      >
        <DialogTitle>
          {t('confirmDeleteEventTitle')}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', gap: 2 }}>
            {t('confirmDeleteEvent', {deleteItem})}
            <Sheet color="danger" variant="soft" sx={{ px: 2, py: 1, borderRadius: 'sm' }}>
              {`Attention cette action est irréversible`}
            </Sheet>
            <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button variant="plain" color="neutral" onClick={handleClose}>                  
                  {t('cancel')}
                  </Button>
                  <Button variant="outlined" color="danger" onClick={handleDelete}>
                  {t('delete')}
                  </Button>
              </Stack>
          </Box>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

export default ConfirmationModalComponent;