import React, { useRef } from 'react'
// Import for routes
import { createBrowserRouter, RouterProvider, Navigate} from 'react-router-dom';


// Import for authentication
import { AuthProvider } from './contexts/AuthContext';
import { ProtectedRoute } from './utils/ProtectedRoute';


import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import engb from 'date-fns/locale/en-GB'; // Ensure you've imported the locale


import NotFoundPage from  './views/notfoundpage'
import PublicPage from  './views/publicpage.js'
import PyroPage from  './views/pyropage/pyropage.js'

import AdminEventsPage from  './views/adminevents/admineventspage.js'
import AdminUsersPage from  './views/adminusers/adminuserspage.js'
import AdminBusinessesPage from './views/adminbusiness/adminbusinessespage.js';

import SigninPage from './views/signinpage.js'
import OnBoardingPage from './views/onboardingpage.js'
import GlobalSettingsPage from './views/globalsettingspage.js';
import EventPaymentSuccessPage from './views/eventPaymentSuccesspage.js';
import EventPaymentErrorPage from './views/eventPaymentErrorpage.js';
import BusinessEventsPage from './views/businesseventspage.js';
import AdminSettingsPage from './views/adminsettingspage.js';

import "./index.js"
import './App.css';


const baseUrl = process.env.REACT_APP_API_URL || ''

const App = () => {
  
  const audioContextRef = useRef(null);

  if (!audioContextRef.current) {
    console.log('Creating AudioContext')
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
  } 

  // Defining router outside APP if the routes are static and don't need to change during the application's lifecycle
  const router = createBrowserRouter([
    {
      path: "/",
      element: 
      <AuthProvider baseUrl={baseUrl}>
        <Navigate to="/signin" replace />, 
      </AuthProvider>,
    },
    {
      path: "/signin",
      element: 
      <AuthProvider baseUrl={baseUrl}>
        <SigninPage baseUrl={baseUrl} />
        </AuthProvider>,
      errorElement: <NotFoundPage />,
    },
    {
      path: "/events/:publicLink",
      element: 
      <AuthProvider baseUrl={baseUrl} isPublic={true}>
        <PublicPage baseUrl={baseUrl} audioContext={audioContextRef.current}/>
      </AuthProvider>,
      errorElement: <NotFoundPage />,
    },
    {
      path: "/events/pyro/:eventId",
      element: 
      <AuthProvider baseUrl={baseUrl} isPublic={true}>
        <PyroPage baseUrl={baseUrl} audioContext={audioContextRef.current}/>      
      </AuthProvider>,
      errorElement: <NotFoundPage />,
    },
    {
      path: "/business/:businessShortName",
      element: 
      <AuthProvider baseUrl={baseUrl} isPublic={true}>
        <BusinessEventsPage baseUrl={baseUrl}/>
      </AuthProvider>,
      errorElement: <NotFoundPage />,
    },
    {
      path: "/admin/events",
      element: (
        <AuthProvider baseUrl={baseUrl}>
          <ProtectedRoute requiredRole="admin">
            <AdminEventsPage baseUrl={baseUrl}/>
          </ProtectedRoute>
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
    {
      path: "/admin/payment/success",
      element: (
        <AuthProvider baseUrl={baseUrl}>
          <ProtectedRoute requiredRole="admin">
            <EventPaymentSuccessPage baseUrl={baseUrl} />
          </ProtectedRoute>
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
    {
      path: "/admin/payment/error",
      element: (
        <AuthProvider baseUrl={baseUrl}>
          <ProtectedRoute requiredRole="admin">
            <EventPaymentErrorPage baseUrl={baseUrl} />
          </ProtectedRoute>
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
    {
      path: "/admin/onboarding",
      element: (
        <AuthProvider baseUrl={baseUrl}>
          <ProtectedRoute requiredRole="admin">
            <OnBoardingPage baseUrl={baseUrl} />
          </ProtectedRoute>
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
    {
      path: "/admin/users",
      element: (
        <AuthProvider baseUrl={baseUrl}>
          <ProtectedRoute requiredRole="super-admin">
            <AdminUsersPage baseUrl={baseUrl}/>
          </ProtectedRoute>
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
    {
      path: "/admin/businesses",
      element: (
        <AuthProvider baseUrl={baseUrl}>
          <ProtectedRoute requiredRole="super-admin">
            <AdminBusinessesPage baseUrl={baseUrl}/>
          </ProtectedRoute>
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
    {
      path: "/admin/settings",
      element: (
        <AuthProvider baseUrl={baseUrl}>
          <ProtectedRoute requiredRole="super-admin">
            <GlobalSettingsPage baseUrl={baseUrl}/>
          </ProtectedRoute>
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
    {
      path: "/settings",
      element: (
        <AuthProvider baseUrl={baseUrl}>
          <ProtectedRoute requiredRole="admin">
            <AdminSettingsPage baseUrl={baseUrl}/>
          </ProtectedRoute>
        </AuthProvider>
      ),
      errorElement: <NotFoundPage />,
    },
    
  ]);

  // document.addEventListener("visibilitychange", function() {
  //   if (document.visibilityState === 'visible') {
  //     console.log('Page is now active');
  //     // Reinitialize your application state here or refresh data
  //   }
  // });
  
  return (    
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={engb}>
        <div className="App">
          <RouterProvider router={router} />
        </div>
    </LocalizationProvider>

  )
};

export default App;